.loginSection {
    margin : 10px 10%;
    text-align: left;
    float: none;}

.inputBlock input{
    margin: 5px;
    /*height: 20px;*/
}
/*.logBtnsBlock{*/
    /*margin-top : 10px;*/
    /*margin-bottom: 10px;*/
    /*margin-left: 20px;*/
/*}*/
.btn-login-save {
    border-radius: 3px;
    background-color: lightblue;
    border : 1px solid lightblue;
    padding: 5px;
    height: 25px;
    font-weight: 600;
    width : 124px;
    margin : 4px 4px 2px;
    /*margin: 0 40px 0 0;*/
}
.btn-login-save:hover {
    cursor: pointer;
    color : #4472C4;
}
.loginSection ol {
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
/*.loginSection div {*/
    /*!*margin: 2px 10px 2px 4px;*!*/
/*}*/
.loginSubSection {
    display: flex;
    justify-content: space-between;
}
.loginSection ol {
    margin : 0 0 10px 0;
}
.loginSection li {
    margin : 2px 0 5px -10px;
}
.loginSubSection {
    border: 1px solid lightgrey;
    border-radius: 3px;
}
.inputBlock {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    padding : -5px 1px;
}
.inputBlockBtns {
    display: flex;
    position: relative;
    flex-direction: column;
    /*align-items: flex-end;*/
    padding : 5px;
    margin : 0 5px;
}
.inputBlock input {
    width : 120px;
}
.inputBlockTop {
    display : flex;
    flex-direction: column;
    border : 1px solid lightgrey;
    border-radius: 5px;
    padding : 0 5px;
    align-items: center;
    justify-content: center;
    margin : 5px 10px;
}
.inputBlockTop2 {
    display : flex;
    /*flex-direction: column;*/
    /*border : 1px solid lightgrey;*/
    /*border-radius: 5px;*/
    padding : 0 5px;
    align-items: center;
    justify-content: center;
    margin : 5px 10px;
}
.popup3 {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    /*margin: 20px 0;*/
}

/* The actual popup (appears on top) */
.popup3 .popuptext3 {
    /*visibility: hidden;*/

    -webkit-animation: hide-animation 0.6s ease-in 1.5s;
    animation: hide-animation 0.6s ease-in 1.5s;
    animation-fill-mode: forwards;

    width: 220px;
    background-color: #f2b436;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    /*margin-left: -180px;*/
    margin: 0 0 -33px -130px;
    /*margin-top : 120px;*/
}

/* Popup arrow */
.popup3 .popuptext3::after {
    content: "";
    position: absolute;
    top: -10px; /*100%;*/
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #f2b436 transparent;
    /*border-color: #f2c236 transparent transparent transparent;*/
}

@keyframes hide-animation {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}
.btn-social{
    opacity : 0.7
}
.btn-social:hover{
    cursor: pointer;
    opacity: 1;
}
.my-facebook-button-class,.my-google-button-class {
    color : white;
    padding : 0 10px;
    border-radius: 5px;
    height: 36px;
    margin : 3px 0;
    text-align: left;
    width : 100%;
}
.my-google-button-class {
    background-color: #DB4437;
    border: solid 1px #DB4437;
}
.my-facebook-button-class {
    background-color: #3b5998;
    border: solid 1px #3b5998;
}
.my-facebook-button-class:hover,.my-google-button-class:hover {
    opacity : 0.9;
    cursor : pointer;
}
.fbLoginBlock {
    display: flex;
    align-items: center;
    flex-direction: row;
    color : white;
    background-color : #3b5998;
    font-weight: 600;
    border : 2px solid #3b5998;
    border-radius: 5px;
    padding : 3px 10px;
    margin : 3px 0;
}
.fbLoginBlock > div {
    margin : 0 5px;
}
.fbLoginBlock:hover {
    cursor: pointer;
    background-color: #8b9dc3;
    border : 2px solid #8b9dc3;
    color : white;
}
.loginBlockHeader {
    display:flex;
    margin : 0 10px;
    justify-content: space-between;
}
@media only screen and (max-width: 400px) {
    .loginSection {
        margin : 5px 5%;
        font-size : 0.8em;
    }
    .inputBlockTop2 {
        margin : 0;
    }
    .inputBlockTop {
        margin : 3px;
    }
    .inputBlock input {
        width : 90px;
    }
    .btn-login-save {
        width : 94px;
    }
    .loginSubSection {
        flex-direction: column;
    }
}