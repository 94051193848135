.loginBlockLight, .loginBlockLightMobile, .loginBlockLightWithPhone, .loginBlockLightMobileWithPhone{
    display: block;
    position: absolute;
    background-color: var(--selected-item);
    box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.4);
    width: 150px;
    padding : 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.loginBlockLight {
    z-index: 11;
    height: 190px;
}
.loginBlockLightWithPhone {
    z-index: 11;
    height: 225px;
}
.loginBlockLightMobile{
    z-index: 50;
    right: 100px;
    height: 175px;
}
.loginBlockLightMobileWithPhone{
    z-index: 50;
    right: 100px;
    height: 225px;
}
.loginBlockLight input {
    width: 95%;
}
.loginButtons > button {
    margin: 10px 5px 5px 0;
    height: 30px;
    border : 1px solid #686868;
    border-radius: 5px;
    width : 45%;
}
.loginButtons {
    display:flex;
    justify-content: space-between;
    flex-direction: row;
}
.my-google-button-class {
    background-color: #DB4437;
    border: solid 1px #DB4437;
}
.my-facebook-button-class {
    background-color: #3b5998;
    border: solid 1px #3b5998;
}
.my-facebook-button-class:hover,.my-google-button-class:hover {
    opacity : 0.9;
    cursor : pointer;
}
.socialBtns {
    display: flex;
    justify-content: space-between;
    /*width : 100%;*/
    /*padding : 0 5%;*/
}
.socialBtns > button {
    width : 80%;
    margin : 2px;
    padding : -2px;
}
.my-login-btn:hover {
    cursor:pointer;
}
.loginblocklight__notice-pwd {
    font-size: .8em;
    font-weight: bold;
    color: #ddebf7;
    margin: 0 2%;
}
.loginblocklight__notice-pwd.disabled {
     color: #555a6e;
 }
.loginblocklight__notice-pwd.enabled:hover {
    cursor : pointer;
    color: #b52227;
}
.loginblocklight__mail-validated{
    position: absolute;
    top: 3px;
    right: 1px;
    font-size: .6em;
    font-weight: bold;
    color: white;
    background-color: #555a6e;
    border-radius: 13px;
    width: 13px;
    text-align: center;
    height: 13px;
}