.addSubject, .addSubjectEx, .addSubjectTop{
    display: block;
    position: absolute;
    z-index: 11;
    border: 2px solid #70c2e6;
    background-color: #e6f6ff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    width: 200px;
    right : 5px;
    padding : 5px;
    color : black;
}
.addSubject, .addSubjectEx{
     bottom: 5px;
 }
.addSubjectTop{
    bottom: -45px;
}
.addSubject, .addSubjectTop{
    height: 90px;
}
.addSubjectEx{
    height: 140px;
}
.addSubject input, .addSubjectTop input, .addSubjectEx input, .addSubjectEx select {
    width: 180px;
    margin : 5px;
}
.addSubject button, .addSubjectTop button, .addSubjectEx button {
    margin: 8px 5px;
}