.subjFromSelector, .subjToSelector {
    width : 170px;
    height : 530px;
}
.subBlocks {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    /*margin: 10px 10%;*/
    border : 1px lightgrey solid;
    padding : 2px;
    height : 580px;
}
.subBlocks > div{
    margin: 2px;
}
.h4 {
    font-weight: 600;
    padding : 5px;
    /*margin-bottom: 5px;*/
}
.tableStats{
    /*margin: 10px 10%;*/
    /*width: 100%;*/
}
/*{name: "Дата", width : "80"} ,*/
/*{name: "Час", width : "20"},*/
/*{name: "Начало", width : "180"},*/
/*{name: "Конец", width : "180"},*/
/*{name: "Минут", width : "20"},*/
/*{name: "Оценок", width : "20"},*/
/*{name: "Оценок/в мин", width : "100%"},*/
.colstat-0 {width: 10%;}
.colstat-1 {width: 5%;}
.colstat-2 {width: 15%;}
.colstat-3 {width: 15%;}
.colstat-4 {width: 5%;}
.colstat-5 {width: 5%;}
.colstat-6 {width: 5%;}

.mym-adminpage-container{
    margin: 10px 0;
}
.mym-adminpage-subjblock {
    display: flex;
    flex-direction: column;
}
.mym-adminpage-subjblock-selectors {
    display: flex;
    flex-direction: row;
}
.mym-adminpage-subjblock {
    display: flex;
    flex-direction: column;
}
.mym-adminpage-translateblock {
    width : 100%;
}
.mym-adminpage-translateblock-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.mym-btn-add-lang-alias {
    border: 2px solid var(--selected-item-hard);
    text-align: center;
    border-radius: 5px;
    background-color: lightgrey;
    width : 80px;
    height : 25px;
}
.mym-btn-add-lang-alias:hover {
    background-color: var(--erase-mark);
    cursor: pointer;
}
.mym-adminpage-subjblock-header {
    background-color: var(--selected-item-background);
    border-radius: 5px;
    padding : 3px;
    margin-bottom: 5px;
}
.mym-adminpage-translateblock-header {
    background-color: var(--selected-item-background);
    border-radius: 5px;
    padding : 3px;
    margin-bottom: 5px;
}