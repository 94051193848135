body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 400px) {
    h2{
        font-size : 0.9em;
    }
}
.primary-school {
    background-color: rgb(255,251,235);
    color: rgb(156, 101, 0);
}
.secondary-school {
    background-color: rgb(255, 244, 205);
    color: rgb(156, 101, 0);
}

.high-school {
    /*background-color: rgb(225,237,247);*/
    /*color: rgb(44,110,170);*/
    background-color: rgb(255,235,156);
    color: rgb(156, 101, 0);
}
.school {
    /*display : flex;*/
    /*flex-direction: row;*/
    margin: 5px 10%;
    text-align: left;
    padding: 0 10px 2px 10px;
    border-radius: 5px;
}
.btn-class {
    margin: 0 3px 3px 0;
    padding: 5px 22px;
    border-radius: 5px;
    border : 1px solid darkgrey;
}
.btn-class:hover {
    background-color: #ececec;
    cursor : pointer;
    color : black;
}
.btn-active {
    /*border : solid 2px black;*/
    background-color: #b4b4b4;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    color: white;
    font-weight: 500;
    border-radius: 5px;
}
h2 {
    margin: 0;
    /*font-family:Helvetica Neue,Helvetica,Arial,sans-serif;*/
}
.buttonsList {
    display : flex;
    flex-direction: row;
}
.classCount {
    position : absolute;
    font-size: 0.7em;
    right : 7px;
    top : 0;
    color : #4472C4;
    font-weight: 700;
}

.rs-container * {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.rs-container {
    font-family: Arial, Helvetica, sans-serif;
    height: 45px;
    position: relative;
}
.rs-container .rs-bg, .rs-container .rs-selected {
    background-color: #eee;
    border: 1px solid #ededed;
    height: 10px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 100%;
    border-radius: 3px;
}
.rs-container .rs-selected {
    background-color: #aed8e6;
    border: 1px solid #7bc4e6;
    transition: all 0.1s linear;
    width: 0;
}
.rs-container.disabled .rs-selected {
    background-color: #ccc;
    border-color: #bbb;
}
.rs-container .rs-pointer {
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 4px;
    cursor: pointer;
    height: 20px;
    left: -10px;
    position: absolute;
    top: 0;
    transition: all 0.1s linear;
    width: 30px;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 6px #ebebeb, 1px 1px 4px rgba(0, 0, 0, 0.1);
}
.rs-container.disabled .rs-pointer {
    border-color: #ccc;
}
.rs-container .rs-pointer::before,
.rs-container .rs-pointer::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 9px;
    background-color: #ddd;
    left: 12px;
    top: 5px;
}
.rs-container .rs-pointer::after {
    left: auto;
    right: 12px;
}
.rs-container.disabled .rs-pointer {
    cursor: default;
}
.rs-container.sliding .rs-selected,
.rs-container.sliding .rs-pointer {
    transition: none;
}
.rs-container .rs-scale {
    left: 0;
    position: absolute;
    top: 5px;
    white-space: nowrap;
}
.rs-container .rs-scale span {
    float: left;
    position: relative;
}
.rs-container .rs-scale span::before {
    background-color: #ededed;
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 1px;
}
.rs-container.rs-noscale span::before {
    display: none;
}
.rs-container.rs-noscale span:first-child::before,
.rs-container.rs-noscale span:last-child::before {
    display: block;
}
.rs-container .rs-scale span:last-child {
    margin-left: -1px;
    width: 0px;
}
.rs-container .rs-scale span ins {
    color: #333;
    display: inline-block;
    font-size: 12px;
    margin-top: 20px;
    text-decoration: none;
    float : left;
}
.rs-container.disabled .rs-scale span ins {
    color: #999;
}
.rs-tooltip {
    color: #333;
    width: auto;
    min-width: 60px;
    height: 30px;
    /*position: relative;*/
    background: #fff;
    border: 1px solid #00969b;
    border-radius: 3px;
    position: absolute;
    -webkit-transform: translate(-50%, -35px);
            transform: translate(-50%, -35px);
    left: 13px;
    text-align: center;
    font-size: 13px;
    padding: 6px 10px 0;
}
.rs-container.disabled .rs-tooltip {
    border-color: #ccc;
    color: #999;
}
.container {
    width: 90%;
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: center;
}
.slider-container {
    /*display: flex;*/
    /*justify-content: center;*/
    width: 80%;
    max-width: 95%;
    /*margin: 10px 60px;*/
    border-radius: 3px;
    padding : 20px 40px 0 40px;
    border : solid 1px #ccc;
}
@media only screen and (max-width: 400px) {
    .slider-container {
        margin: 10px 5%;
        padding : 20px 15px 0;
    }
}
@media only screen and (max-width: 400px) {
    .subjclass, .subjclass_selected, .subjclass_added{
        font-size : 0.8em;
        margin : 3px;
        padding: 3px 5px;
    }
    #subjects {
        width: auto;
    }
}

.subjMain {
    width : 100%;
}
#subjects {
    margin: 0 10%;
    position: relative;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    padding : 5px 5px;
    border : solid 1px #ccc;
    color : #b4b4b4;
    /*flex-wrap: wrap;*/
}

.active {
     background-color: #7DA8E6; /*rgba(3,17,230,.5)*//*#4CAF50*/;
     color : white;
 }
.activeadd {
    background-color: #e6f6ff; /*rgba(3,17,230,.5)*//*#4CAF50*/;
    color : black;
}
.active_selected {
    background-color: #409be6; /*rgba(3,17,230,.5)*//*#4CAF50*/;
    color : lightyellow;
}
.noactive {
    background-color: #020A84; /*rgba(3,17,230,.5)*//*#4CAF50*/;
    color : white;
}
/*.noactive_selected {*/
/*background-color: #7DA8E6; !*rgba(3,17,230,.5)*!!*#4CAF50*!;*/
/*color : white;*/
/*}*/
li.dropdown {
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    /*min-width: 160px;*/
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: inline-block;
    float: left;
    text-align: left;
}

.dropdown-content a:hover {
    color: white;
    background-color: #020A84/*#4CAF50*/;
}

.dropdown:hover .dropdown-content{
    display: inline-block;
    float: left;
    z-index: 100;
    color: white;
}

.classlist:hover {
    color: white;
}
/*#navbar {*/
    /*display: flex;*/
    /*position: relative;*/
    /*align-items  : center;*/
/*}*/
.labelinput {
    /*position: relative;*/
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding : 11px 16px;
    background-color: #020A84/*#333*/;
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    margin: 0 10px;
    /*margin: -38px 0 0 0;*/
}
.labelinput input {
    width : 60px;
    height: 20px;

}
@-webkit-keyframes flash {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes flash {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.subjclass {
    display: inline-block;
    border : 1.3px #b4b4b4 solid;
    text-align: center;
    height: 20px;
    margin : 5px;
    padding: 5px 10px;
    opacity: 0;
    -webkit-animation-name: flash;
            animation-name: flash;
    border-radius: 5px;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.subjclass.addbutton{
    background-color: rgb(255,251,235);
}
.subjclass.addbutton:hover{
    /*border : solid 1px rgb(68,114,196);*/
}
.subjclass-added {
    display: inline-block;
    border : 1.3px #fff106 solid;
    color : #fff106;
    text-align: center;
    height: 20px;
    margin : 5px;
    padding: 5px 10px;
    opacity: 0;
    -webkit-animation-name: flash;
            animation-name: flash;
    border-radius: 5px;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.subjclass_selected {
    display: inline-block;
    border : 1.3px white solid;
    /*background-color: gainsboro;*/
    color : white;
    text-align: center;
    height: 20px;
    margin : 5px;
    padding: 5px 10px;
    opacity: 0;
    -webkit-animation-name: flash;
            animation-name: flash;
    border-radius: 5px;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    /*background-color: #7DA8E6;*/
}
.subjclass:hover {
    /*border: 1.3px yellow solid;*/
    /*color : yellow;*/
    color : black/*rgb(68,114,196)*/;
    border : solid 1.3px rgb(68,114,196);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    cursor: pointer;
}
.subjclass_selected:hover {
    border: 1.3px yellow solid;
    color : yellow;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    cursor: pointer;
}
.dropdown.submarks .dropdown-content {
    opacity: 0;
    display: inline-block;
    float: left;
    position: absolute;
    background-color: #7DA8E6;
    color: black;
}
.marks {
    display: flex;
    text-align: center;
    color : #020A84;
    justify-content: space-around;
}
.dropdown-content2 {
    /*display: none;*/
    position: absolute;
    background-color: #f9f9f9;
    /*min-width: 160px;*/
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    z-index: 1;
}
#subjects_selected {
    display: inline-block;
    text-align: center;
    margin: 50px 10%;
}


.addSubject, .addSubjectEx, .addSubjectTop{
    display: block;
    position: absolute;
    z-index: 11;
    border: 2px solid #70c2e6;
    background-color: #e6f6ff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    width: 200px;
    right : 5px;
    padding : 5px;
    color : black;
}
.addSubject, .addSubjectEx{
     bottom: 5px;
 }
.addSubjectTop{
    bottom: -45px;
}
.addSubject, .addSubjectTop{
    height: 90px;
}
.addSubjectEx{
    height: 140px;
}
.addSubject input, .addSubjectTop input, .addSubjectEx input, .addSubjectEx select {
    width: 180px;
    margin : 5px;
}
.addSubject button, .addSubjectTop button, .addSubjectEx button {
    margin: 8px 5px;
}
.block-0 {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 5px 10%;
    border-radius: 5px;
    height: 50px;
    border : solid 1px #c4c4c4;
    z-index: 3;
}

.block-0-0 {
    display: flex;
    position: relative;
    padding: 0 10px;
    width : 65%;
    z-index: 3;
}
.block-0-1 {
    position : relative;
    width : 35%;
    margin-right : 5px;
}
.block-0-2 {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    /*padding: 10px 0;*/
    width : 5%;
    height: 100%;
    border : 1px solid darkgray;
    background-color: #f0f0f0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right : -1px;
}
.geoMain {
    position: relative;
}
.geoLocation {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    /*margin-left: 10px;*/
    color : rgb(68,114,196);
    background-color: white;
    border: 1px solid #c4c4c4;
    border-radius : 10px;
    padding : 0 20px;
    margin : 15px;
    height: 30px;
    box-shadow: 2px 2px 5px 0px #c4c4c4;
    /*right: 10px;*/
    /*padding: 0 10px;*/
}
@media only screen and (max-width: 400px) {
    .block-0 {
        margin: 5px 5%;
    }
    .geoLocation{
        padding : 0 20px 0 10px;
        margin : 15px 5px 15px 10px;
        font-size: 0.8em;
    }
}
.geoCity {
    position : relative;
    padding-right: 5px;
    padding-left: 5px;
}
.geoCountryCode {
    position: absolute;
    right : -16px;
    top : 0;
    /*border : 0.5px solid darkgrey;*/
    background-color: #e2e2e2;
    border-radius: 5px;
    font-size: 0.6em;
    font-weight: 700;
    padding : 0 3px;
    color: black;
}
.block-0-2 a span {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left: 0;
    /* edit: added z-index */
    z-index: 1;

    /* edit: fixes overlap error in IE7/8,
       make sure you have an empty gif */
    /*background-image: url('empty.gif');*/
}
.block-0-2:hover {
    cursor: pointer;
    background-color: rgba(64, 155, 230, 0.16)
}
.done {
    background-color: rgba(64, 155, 230, 0.16) /*rgb(226,239,218)*/;
}
.block-1 {
    transition: 0.5s;
}
.block-0.activeblock {
    border : solid 1.5px #4472C4;

}
.block-0.activeblock h3 {
    color : rgb(68,114,196);
}
/*$ani-speed: 2s;*/
/*$ani-delay: 1s;*/

.arrow {
    opacity: 0;
    -webkit-animation: arrow-movement 2s ease-in-out infinite;
            animation: arrow-movement 2s ease-in-out infinite;
}
.saveDiv {
    background-color: rgb(255, 244, 205);
    color: rgb(156, 101, 0);
}

@-webkit-keyframes arrow-movement {
    0% {
        opacity: 0;
        /*top: 45%;*/
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes arrow-movement {
    0% {
        opacity: 0;
        /*top: 45%;*/
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.arrows {
    /*position: relative;*/
    /**/
    position: absolute;
    /*top:50%;*/
    left:85%;
    /*right:100%;*/
    /*float : right;*/
    /**/
    width:10px;
    height:10px;
    -webkit-transform:translate(-50%,-50%);
            transform:translate(-50%,-50%);

}
.arrows:after {
     content: '';
     position: absolute;
     width:100%;
     height:100%;
     border-left:calc(10px/3) solid rgba(180, 180, 180, 0.7);
     border-bottom:calc(10px/3) solid rgba(180, 180, 180,0.7);
     -webkit-transform:translate(calc(10px*2/3),0px)rotate(-45deg) ;
             transform:translate(calc(10px*2/3),0px)rotate(-45deg) ;
     -webkit-animation: arrows 2s linear infinite calc(2s/-2);
             animation: arrows 2s linear infinite calc(2s/-2);
 }
.arrows:before {
     content: '';
     position: absolute;
     width:100%;
     height:100%;
     border-left:calc(10px/3) solid rgba(180, 180, 180,0.7);
     border-bottom:calc(10px/3) solid rgba(180, 180, 180,0.7);
     -webkit-transform:translate(calc(10px/3),calc(10px*4/3))rotate(-45deg);
             transform:translate(calc(10px/3),calc(10px*4/3))rotate(-45deg);
     -webkit-animation: arrows 2s linear infinite;
             animation: arrows 2s linear infinite;
 }
/*}*/

@-webkit-keyframes arrows {
    0% {
        border-left:calc(10px/3) solid rgba(0,0,0,0);
        border-bottom:calc(10px/3) solid rgba(0,0,0,0);
        -webkit-transform:translate(calc(10px/-6),calc(10px*4/-6))rotate(-45deg) ;
                transform:translate(calc(10px/-6),calc(10px*4/-6))rotate(-45deg) ;
    }
    10%, 90% {
        border-left:calc(10px/3) solid rgba(0,0,0,0);
        border-bottom:calc(10px/3) solid rgba(0,0,0,0);
    }
    50% {
        border-left:calc(10px/3) solid rgba(180, 180, 180,0.7);
        border-bottom:calc(10px/3) solid rgba(180, 180, 180,0.7);
        -webkit-transform:translate(calc(10px/-6),0px)rotate(-45deg) ;
                transform:translate(calc(10px/-6),0px)rotate(-45deg) ;
    }
    100% {
        border-left:calc(10px/3) solid rgba(0,0,0,0);
        border-bottom:calc(10px/3) solid rgba(0,0,0,0);
        -webkit-transform:translate(calc(10px/-6),calc(10px*4/6))rotate(-45deg) ;
                transform:translate(calc(10px/-6),calc(10px*4/6))rotate(-45deg) ;
    }
}

@keyframes arrows {
    0% {
        border-left:calc(10px/3) solid rgba(0,0,0,0);
        border-bottom:calc(10px/3) solid rgba(0,0,0,0);
        -webkit-transform:translate(calc(10px/-6),calc(10px*4/-6))rotate(-45deg) ;
                transform:translate(calc(10px/-6),calc(10px*4/-6))rotate(-45deg) ;
    }
    10%, 90% {
        border-left:calc(10px/3) solid rgba(0,0,0,0);
        border-bottom:calc(10px/3) solid rgba(0,0,0,0);
    }
    50% {
        border-left:calc(10px/3) solid rgba(180, 180, 180,0.7);
        border-bottom:calc(10px/3) solid rgba(180, 180, 180,0.7);
        -webkit-transform:translate(calc(10px/-6),0px)rotate(-45deg) ;
                transform:translate(calc(10px/-6),0px)rotate(-45deg) ;
    }
    100% {
        border-left:calc(10px/3) solid rgba(0,0,0,0);
        border-bottom:calc(10px/3) solid rgba(0,0,0,0);
        -webkit-transform:translate(calc(10px/-6),calc(10px*4/6))rotate(-45deg) ;
                transform:translate(calc(10px/-6),calc(10px*4/6))rotate(-45deg) ;
    }
}
.titleBlock:hover{
    cursor : pointer;
}
.titleBlock {
    position: relative;
    z-index:2;
    transition-duration: 2s;
}
#buttons {
    /*display: flex;*/
    display : none;
    margin: 0 10%;
    position : absolute;
    width : 100%;
    height: 50px;
    border-radius: 5px;
}

.btn{
    display: inline-block;
    visibility: hidden;
    position: relative;
    border : 1px solid #629de6;
    padding: 10px;
    height: 20px;
    width: 20px;
    top: -1px;
    z-index: 0;
    /*margin: 5px;*/
    margin-right: 5px;
    border-radius: 5px;
}
.btn2{
    display: inline-block;
    visibility: hidden;
    position: relative;
    border : 1px solid #629de6;
    border-radius: 5px;
    padding: 10px;
    height: 20px;
    width: 20px;
    top: -1px;
}
.markDiv {
    position:relative;
    text-align: right;
    padding-right: 20px;
    margin-right: 10px;
    height : 25px;
}
.markTopLabel{
    position:absolute;
    top:-5px;
    right:0;
    border: 1px solid rgb(255,235,156);
    background-color: rgb(255,235,156);
    border-radius: 5px;
    color: #040a84;
    font-size: 0.5em;
    padding : 2px;
}
.markBottomLabel{
    position:absolute;
    bottom:-5px;
    right:0;
    font-size: 0.5em;
}
.disabledSave {
    color : #686868;
    background-color: lightgrey;
}
:root {
    --border-color : darkgrey;
    --msg-header-color : #33ccff;
    --msg-header-color-new : #f2b436;
    --msg-hw-color : #b40530;
    --msg-hw-color-light : #ecabb9;
    --msg-left-side : #edf0f2;
    --msg-right-side : rgba(163, 234, 247, 0.49);

    --selected-item: #7DA8E6;
    --selected-item-hard: #4472C4;
    --selected-item-background : rgba(64, 155, 230, 0.25);
    --selected-item-hover : rgba(64, 155, 230, 0.16);
    --shadow : rgba(0,0,0,0.3);

    --mark-text : #C00000;
    --excel-mark : #87DD97;
    --good-mark: #C6EFCE;
    --excel-mark-font : #387541;
    --norm-mark: #FFEB9C;
    --norm-mark-font: #d87b38;
    --bad-mark: #FF8594;
    --erase-mark: #d4d4d4;

    --navbar-color : #f0f0f0;
    --menu-item: #1890e6;
    --menu-homework : #E7AD00;

    --timetable0 : #ddebf7;
    --timetable1 : #bdd7ee;
    --timetable2 : #b4c6e7;
    --timetable3 : #b4c6e7;
}
.markBlanks {
    display: flex;
    justify-content: space-between;
    height : 280px;
}
.withborder{
    border : 1px solid lightgrey;
    border-radius: 3px;
    z-index: 2;
}
.dropdown-main-0 {
    position: absolute;
}
@media only screen and (max-width: 400px) {
    .markBlanks {
        margin : 5px 2%;
    }
    .ul12 {
        width : 50px;
    }
    .withborder{
        padding : 10px 2px;
    }
    .dropdown-main-0{
        /*margin : 5px 0 0 5px;*/
    }
    .ul12,.ul5,.ulAF {
        width : 94px;
    }
}
@media only screen and (min-width: 400px) {
    .markBlanks {
        margin : 15px 30%;
    }
    .withborder{
        padding: 10px 20px;
    }
    .ul12,.ul5,.ulAF {
        width : 104px;
    }
    .dropdown-main-0{
        margin: -5px 0 0 15px;
    }
}
ul {

    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px var(--shadow);
}

.uTT {
    width : 154px;
}
li {
    display: inline-block;
    float: left;
}
li.li-title {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 18px;
    text-decoration: none;
}
/* Change the link color to #111 (black) on hover */
li a:hover {
    background-color: var(--selected-item);
}
.nohover{
    pointer-events: none;  /**<-----------*/
}
.active {
    background-color: var(--selected-item);
}
/*.active_selected {*/
    /*background-color: #409be6; */
    /*color : lightyellow;*/
/*}*/
/*.noactive {*/
    /*background-color: #020A84; !*rgba(3,17,230,.5)*!!*#4CAF50*!;*/
    /*!*color : white;*!*/
/*}*/
@-webkit-keyframes flash {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes flash {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.dropdown-content3, .dropdown-content3-line {
    display: flex;
    justify-content: space-around;
    font-size: 0.7em;
    font-weight: 600;
    width: 100%;
}
.dropdown-content3-line {
    color: black;
}
.dropdown-content3 {
    color: white;
}
.dropdown-content3 ul, .dropdown-content3-line ul {
    width: 100%;
}
.dropdown-content3 ul li, .dropdown-content3-line ul li{
    width: 33.33%;
}
.dropdown-content3 ul.fivemarks li{
    width: 99.99%;
}
.dropdown-content3 ul.fiveletters li{
    width: 99.99%;
}
.dropdown-content0 {
    display: flex;
    justify-content: space-around;
    color: white;
    font-size: 0.7em;
    font-weight: 600;
    width: 100%;
}
.dropdown-content0 ul {
    width: 100%;
}
.dropdown-content0 ul li{
    width: 100%;
}
.marks12,.marks9,.marks6,.marks3,.marks0,.timetable0,.timetable1,.timetable2,.timetable3{
    /*color : var(--mark-text)*/
}
.marks12{
    background-color: var(--excel-mark);
}
.marks9{
    background-color: var(--good-mark);
}
.marks6{
    background-color: var(--norm-mark);
}
.marks3{
    background-color: var(--bad-mark);
}
.marks0{
    background-color: var(--erase-mark);
}
.marksHeader {
    background-color: var(--selected-item);
    color : #fff;
}
.timetable0{
    background-color: var(--timetable0);
    color : #2f75b5;
}
.timetable1{
    background-color: var(--timetable1);
    color : #2f75b5;
}
.timetable2{
    background-color: var(--timetable2);
    color : #2f75b5;
}
.timetable3{
    background-color: var(--timetable3);
    color : #2f75b5;
}
#markblank{
    /*display: none;*/
}
#markblank_five, #markblank_twelve, #markblank_letters {
    z-index: 3;
}

#markblank_five.selected, #markblank_twelve.selected, #markblank_letters.selected {
    border : 1px solid var(--selected-item-hard);
    border-radius: 3px;
    background-color: var(--selected-item-background);
}
#markblank_five:hover, #markblank_twelve:hover, #markblank_letters:hover{
    /*background-color: rgba(64, 155, 230, 0.16);*/
    background-color: var(--selected-item-hover);
    cursor: pointer;
}

ul.marks12>li>a,ul.marks9>li>a,ul.marks6>li>a,ul.marks3>li>a,ul.marks0>li>a,ul.timetable0>li>a,ul.timetable1>li>a,ul.timetable2>li>a,ul.timetable3>li>a {
    display: block;
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 600;
}
ul.marks12>li>a,ul.marks9>li>a {
    color : var(--excel-mark-font);
}
ul.marks6>li>a {
    color : var(--norm-mark-font);
}
ul.marks3>li>a {
    color : var(--msg-hw-color);
}
ul.marks0>li>a {
    color : #4d4d4d;
}
ul.timetable0>li>a,ul.timetable1>li>a,ul.timetable2>li>a,ul.timetable3>li>a {
    color : var(--selected-item-hard);
}
ul.marks12>li>a:hover,ul.marks9>li>a:hover,ul.marks6>li>a:hover,ul.marks3>li>a:hover,ul.marks0>li>a:hover,ul.timetable0>li>a:hover,ul.timetable1>li>a:hover,ul.timetable2>li>a:hover,ul.timetable3>li>a:hover {
    color : white;
}
.show {
    display: block;
}
#leftrect{
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 0;
    border-color: var(--mark-text) transparent transparent transparent;
}
li>a:hover {
    cursor: pointer;
}



.checkboxLabel{
    text-align: left;
    margin: 1px 0;
}
.radioMap {
    display: flex;
    border : solid 1px lightgrey;
    justify-content: space-around;
    /*margin: 10px 10%;*/
    padding: 10px 20px;
}
.radioTitle {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
    padding: 5px 5px;
    text-align: left;
}
.customInput{
    width : 60px;
}
.selSection {
    display: flex;
    align-items: center;
    padding : 0 5px;
    margin: 0 5px;
}
.selSectionV {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding : 0 2px;
    margin: 0 5px;
}
.selSection select {
    width : 120px;
    height: 25px;
}
.selSectionV > pre{
    height: 10px;
}
th {
    font-size: 0.7em;
}
table {
    display: block;
    overflow: auto;
    max-width: 100%;
}
table, th, td{
    border-collapse:collapse;
    border:1px solid #b4b4b4;
}
.badmark{
    background-color: rgb(255,133,148);
    color:white;
}
td {
    width: 20px;
    height: 20px;
    padding: 3px;
}
td:hover {
    background-color: rgba(64, 155, 230, 0.16) /*#7DA8E6*/;
}
th.numbercol{
    width : 30px;
    text-align: center;
    font-size: 1em;
}
/*numbercolinlist{*/
    /*!*width : 30px;*!*/
    /*!*text-align: center;*!*/
    /*!*font-size: 1em;*!*/
    /*background-color: rgba(64, 155, 230, 0.25);*/
/*}*/
th.namescol{
    width : 180px;
    text-align: left;
    /*padding : 0 5px;*/
    font-size: 1.1em;
}
th.namescol.head{
    text-align: center;
}
th.tablebody,td.tablebody,th.tablehead {
    width: 35px;
    height: 30px;
    font-size: .9em;
}
.tablebody{
    position: relative;
}
td,th{
    padding: 0;
    /*text-align: center;*/
}
td.tableBody{
    text-align : center;
}
.containertable{
    margin: 10px 10%;
}
tr#row-1, tr#row-2{
    background-color: lightgrey;
}
#simple-board {
    width : 100%;
    /*overflow:scroll;*/
    /*margin: 10px 10%;*/
}
.namescol.nameselected{
    box-shadow: 0px 0px 2px 2px #1c5096;
}
.tablehead.dateselected{
    /*color : #1c5096;*/
    border-bottom : 2px solid #1c5096;
}
#daysback, #daysforward, #daysforwardone {
    font-size: .9em;
    font-weight: 500;
    padding : 3px 8px;
    /*padding-right: 10px;*/
    align-items: center;
    margin : 5px;
    border : 1px solid #c4c4c4;
    border-radius: 5px;
}
#daysback:hover, #daysforward:hover, #daysforwardone:hover {
    box-shadow: 0px 0px 2px 2px #c4c4c4;
    border-radius: 5px;
    background-color: #c4c4c4;
    border : 1px solid #7DA8E6;
    color : white;
    cursor: pointer;
}
.periodName {
    position: relative;
    display : flex;
    margin : 5px 0;
    align-items: center;
    justify-content: space-between;
}
.selectGroup {
    display : flex;
    position : relative;
    /*margin: 1px 0;*/
    justify-content: space-between;
}
.selectGroup > label {
    margin-left: 10px;
}
.tablehead{
    /*color:#52aee6;*/
    background-color: lightgrey;
    position: relative;
}
.selectGroup-btn {
    /*border : #4472C4;*/
    border : 1px solid grey;
    color : #4472C4;
    background-color: #F4F4F4;
    padding: 2px;
    border-radius: 5px;
    width : 140px;
    font-size: .8em;
    text-align: center;
}
.selectGroup-btn:hover, .btn-showStat:hover {
    color: #d87b38;
    cursor: pointer;
}
.markstable-showstat {
    position : absolute;
    width : 400px;
    height : auto;
    background-color: white;
    border : .5px solid var(--excel-mark-font);
    border-radius: 5px;
    margin-top: 2px;
    z-index : 120;
    box-shadow: 5px 5px 5px 0 #c4c4c4;
}
.btn-showStat {
    border : 1px solid grey;
    color : #4472C4;
    background-color: lightgrey;
    padding: 2px;
    border-radius: 5px;
    width : 140px;
    font-size: .8em;
    text-align: center;
}
.btn-showStat:hover {
    cursor : pointer;
}
.btn-close {
    position : absolute;
    top : 1px;
    right : 2px;
    font-size: .8em;
    font-weight: 900;
    color : white;
}
.btn-close:hover {
    cursor : pointer;

}

/* Popup container */
.popup2 {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* The actual popup (appears on top) */
.popup2 .popuptext2 {
    /*visibility: hidden;*/
    -webkit-animation: hide-animation 0.6s ease-in 1.5s;
    animation: hide-animation 0.6s ease-in 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    right: 0;
    margin: -20px 0 0 -80px;
}

/* Popup arrow */
.popup2 .popuptext2::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
/*.popup .show {*/
    /*visibility: visible;*/
    /*-webkit-animation: fadeIn 1s;*/
    /*animation: fadeIn 1s*/
/*}*/

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
}

@-webkit-keyframes hide-animation {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes hide-animation {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}
.addMarkTypes{
    position: absolute;
    display:flex;
    opacity : 0.4;
    width: 160px;
    height: 25px;
    top : 0;
    overflow: visible;
    z-index: 100;
}
.addMarkTypes-selected{
    position: absolute;
    display:flex;
    opacity : 1;
    width: 160px;
    height: 25px;
    top : 0;
}
.addMarkTypes:hover{
    opacity: 1;
    cursor: pointer;
}
.markType{
    background-color: #7DA8E6;
    position: relative;
    color:white;
    text-align: center;
    font-weight: 800;
    border: 1px solid #3892e6;
    width : 33%;
    z-index: 101;
}
.markType-selected{
    background-color: #040a84;
    position: relative;
    color: #ffe699;
    text-align: center;
    font-weight: 800;
    border: 1px solid #040a84;
    width : 33%;
    z-index: 101;
}
.hoverText{
    position: absolute;
    top: 20px;
    left: 10px;
    display: none;
    padding: 2px 5px;
    background-color: lightyellow;
    color: #3892e6;
    transition: height 1s;
    -webkit-transition: height 1s;
    z-index: 102;
}
.markType:hover .hoverText{
    display: block;
    overflow: visible;
    z-index: 102;
}
.markType-selected:hover .hoverText{
    display: block;
    overflow: visible;
    z-index: 102;
}
.topMarkLabel{
    position: absolute;
    right : 1px;
    top : 0;
    font-size: 0.6em;
    color: #b52227;
    font-weight: 700;
}
.topMarkLabelBefore{
    position: absolute;
    left : 0;
    top : 0;
    font-size: 0.6em;
    color: #3257e6;
    font-weight: 700;
}
.bottomDoubleMark{
    position: absolute;
    right : 1px;
    bottom : 0;
    font-size: 0.6em;
    color: #b52227;
    font-weight: 700;
}
.mondayFlag,.holidayFlag{
    position : absolute;
    font-size: 0.6em;
    right :0;
    top : -6px;
}
.holidayFlag{ color: #b52227; }
.mondayFlag{ color: #3257e6; }
.markstable-doubleDay {
    position : absolute;
    font-size: 0.6em;
    right : 0;
    bottom : -6px;
    /*color: #3257e6;*/
}
.tableheadtd {
    position : relative;
}
.periodTitle {
    border : 1px solid  #c4c4c4;
    border-radius: 5px;
    padding : 8px 0;
    font-size: .85em;
}
.markTableStatCaption{
    position : absolute;
    font-size : 0.8em;
    right : 0;
    top : 0;
    color : var(--selected-item-hard);
    margin-top : -5px;
}
.markstable-checkbox-group{
    display: flex;
    flex-direction: column;
}
.subjStatName:hover{
    cursor : pointer
}
.markstable__selected-cell{
    background-color : var(--selected-item-hover);
}
.markstable__selected-student {
    border : 2px solid #7DA8E6;
    position : fixed;
    top : 50px;
    height: 30px;
    width : 200px;
    margin-top : 10px;
    background-color: #7DA8E6;
    color : white;
    box-shadow: 3px 3px 3px 0 #c4c4c4;
    z-index: 3;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    left : 2%;
}
.markstable__selected-student.hidden {
    display: none;
}
th {
    font-size: 0.7em;
}
table {
    display: block;
    overflow: auto;
    max-width: 100%;
}
table, th, td{
    border-collapse:collapse;
    border:1px solid #b4b4b4;
}
td {
    width: 20px;
    height: 20px;
    padding: 3px;
}
td:hover {
    background-color: rgba(64, 155, 230, 0.16) /*#7DA8E6*/;
}
th.numbercol{
    width : 30px;
    text-align: center;
    font-size: 1em;
}
th.namescol{
    width : 180px;
    text-align: left;
    padding : -3px 0px;
    font-size: 1.1em;
}
th.namescol.head{
    text-align: center;
}
th.tablebody, td.tablebody, th.tablehead {
    width: 35px;
    height: 30px;
    font-size: .9em;
}
td, th{
    padding: 0;
    /*text-align: center;*/
}
td.tableBody{
    text-align : center;
}
.containertable{
    margin: 10px 10%;
}
tr#row-1, tr#row-2{
    background-color: lightgrey;
}
#simple-board {
    width : 100%;
    /*margin: 10px 10%;*/
}
/*.namescol.nameselected{*/
    /*box-shadow: 0px 0px 4px 6px #feff77;*/
/*}*/
/*.tablehead.dateselected{*/
    /*color : #feff77;*/
/*}*/
#daysback, #daysforward, #daysforwardone {
    font-size: 1em;
    font-weight: 500;
    padding : 3px 10px;
    /*padding-right: 10px;*/
    align-items: center;
    margin : 10px 10px;
    border : 1px solid #c4c4c4;
    border-radius: 5px;
}
#daysback:hover, #daysforward:hover, #daysforwardone:hover {
    box-shadow: 0px 0px 2px 2px #c4c4c4;
    border-radius: 5px;
    background-color: #c4c4c4;
    border : 1px solid #7DA8E6;
    color : white;
    cursor: pointer;
}
.periodName {
    display : flex;
    margin : 5px 0;
    align-items: center;
}
.selectGroup {
    display : flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
}
.subjButton {
    /*padding-left: 10px;*/
    padding: 3px 0 3px 10px;
}
.subjButton.active {
    /*background-color: rgb(47,117,181);*/
    background-color: lightgrey;
    /*color : #ffe699;*/
    padding: 3px 0 3px 10px;
    /*color : white;*/
}
.subjButton:hover{
    cursor: pointer;
}
.markType:hover .hoverText{
    display: block;
    overflow: visible;
}
.markType-selected:hover .hoverText{
    display: block;
    overflow: visible;
}
.topMarkLabel{
    position: absolute;
    right : 1px;
    top : 0;
    font-size: 0.6em;
    color: #b52227;
    font-weight: 700;
}
.topMarkLabelBefore{
    position: absolute;
    left : 1px;
    top : 0;
    font-size: 0.6em;
    color: #3257e6;
    font-weight: 700;
}
/*.mondayFlagStudent{*/
    /*position : absolute;*/
    /*font-size: 0.6em;*/
    /*right :0;*/
    /*top : -2px;*/
    /*color: #3257e6;*/
/*}*/
.mondayFlagStudent,.holidayFlagStudent{
    position : absolute;
    font-size: 0.6em;
    right :0;
    top : 0;
}
.holidayFlagStudent{ color: #b52227; }
.mondayFlagStudent{ color: #3257e6; }

.bottomDoubleMark{
    position: absolute;
    right : 1px;
    bottom : 0;
    font-size: 0.6em;
    color: #b52227;
    font-weight: 700;
}
.tableheadtd {
    position : relative;

}
@media only screen and (max-width: 400px) {
    .containertable {
        margin: 5px 5%;
        font-size: 0.8em;
    }
}
.badmark{
    background-color: rgb(255,133,148);
    color:white;
}
.studcol{
    font-size: 0.8em;
}
.periodTitle {
    border : 1px solid  #c4c4c4;
    border-radius: 5px;
    padding : 8px 0;
}
.emailList {
    display: inline-block;
    border : 1px solid lightblue;
    width : 70%;
    margin: 5px;
    font-size: 1em;
}
@media only screen and (max-width: 400px) {
    .emailList {
        width : 95%;
    }
}
.emailListHeader, .defaultItemInEmailList, .itemInEmailList {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding : 5px;
}
.emailListHeader {         font-weight: 700; background-color: rgb(191,191,191);}
.defaultItemInEmailList {      font-weight: 600;   background-color: rgb(242,242,242);}
.itemInEmailList {         font-weight: 400; background-color: white;}

.btnDelMail{
    border : 1px solid darkgrey;
    padding : 5px;
    font-weight: 700;
    text-align: center;
}
.fastRegAndMail {
    border : .5px solid darkgrey;
    border-radius: 5px;
    margin : 0 0 0 5px;
    padding: 0 10px 2px 10px;
    text-align: center;
    background-color: #FFEB9C;
}
.fastRegAndMail:hover{
    cursor : pointer;
    color: #6a6a6a;
}
.emaiListAddButton{
    border : 0.5px solid #4472C4;
    border-radius: 5px;
    padding : 0 20px;
    font-weight: 600;
    font-size: 1.5em;
    color : #588f62;
}
.emaiListAddButton:hover{
    cursor: pointer;
    background-color: lightblue;
}
.emaiListRemoveButton{
    border : 0.5px solid #4472C4;
    border-radius: 5px;
    padding : 0 20px;
    font-weight: 600;
    font-size: 1.1em;
    color: #b40530;
}
.emaiListRemoveButton:hover{
    cursor: pointer;
    background-color: lightblue;
}
.email-buttons{
    display: flex;
    justify-content: space-between;
    width : 50%;
}
@media only screen and (max-width: 400px) {
  .app-homeWorkSection {
    margin-left: 5%;
  }
  .btn-chat-new {
    left: 3%;
  }
 .btn-videocam {
    right : 3%;
  }
}
@media only screen and (min-width: 400px) {
  .descrAndAnnounceMobile{
    width : 100%;
  }
  .description, .description-main {
    margin : 10px 0;
  }
  .btn-chat-new {
    right: 10%;
  }
}
@media only screen and (max-width: 400px) {
  .description, .description-main{
    font-size: 0.7em;
    margin: 5px 10%;
  }
  .addRef {
    padding: 2px 0 2px;
    font-size: 0.8em;
  }
  #markblank-1,#markblank-2,#markblank-3{
    width: 30%;
  }
  .descrHeader > h4 {
    font-size: 0.8em;
    margin : 5px 0;
  }
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.description-main {
  margin: 10px 0;
  text-align: left;
  /*width : 90%;*/
}
.description {
  margin: 15px 10%;
  text-align: left;
}
h3 {
  /*padding: -20px;*/
  display: inline-block;
  text-align: left;
  float: left;
}
.navbar {
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  background-color: var(--navbar-color);
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  /*width: 100%; !* Full width *!*/
  left : 0;
  right : 0;
  height: 50px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.navbar-shadow {
  position: relative;
  top: 0; /* Position the navbar at the top of the page */
  width: 100%;
  height: 50px;
}
.block-0 {
  display: flex;
  align-items: center;
  justify-content: left;
  /*margin: 5px 10%;*/
  border-radius: 5px;
  height: 40px;
  border : solid 1px #c4c4c4;
}
.block-0-0 {
  /*border : solid 1px #c4c4c4;*/
  padding: 0 10px;
  width : 55%;
}
.block-0-1 {
  width : 35%;
  margin-right : 5px;
  /*border : solid 1px #c4c4c4;*/
}
.block-0-2 {
  width : 10%;
  /*border : solid 1px #c4c4c4;*/
}
.additionalSection {
  margin : 10px 10%;
  border : solid 1px lightgrey;
  padding: 10px;
  position: relative;
}
.additionalSection > button {
  margin-right : 20px;
}
.block-1, .block-2 {
  opactity : 1;
}
.menuBlock {
  position: relative;
  margin : 10px;
}
.logBtnsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  right : 0;
  /*margin : 10px;*/
}
a {
  color: black;
  text-decoration: none;
}
button.loginbtn {
  border-radius: 10px;
  border : 1px solid var(--selected-item);
  color: var(--navbar-color);
  background-color: var(--selected-item);/*var(--selected-item);*/
  padding: 5px;
  height: 30px;
  /*margin: 0 5px 0 0;*/
  width: 100px;
  font-weight: 600;
  outline: none;
}
button.logoutbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border : 1px solid var(--selected-item);
  /*background-color: var(--selected-item-background);*/
  background-color: var(--selected-item);/*var(--selected-item);*/
  color: white;
  padding: 0 8px;
  height: 30px;
  /*margin: 0 5px 0 0;*/
  min-width: 150px;
  max-width: 270px;
  width: auto;
  font-weight: 600;
  outline: none;
}
button.loginbtn:hover {
  cursor: pointer;
  /*background-color: var(--selected-item-background);*/
}
button.logoutbtn:hover {
  cursor: pointer;
}

.infoMsg {
  color: #52aee6;
}
.infoMsgAndroid {
  color : #a4c639;
  font-weight : 700;
}
.downloadAdnroid {
  display : flex;
  align-items: center;
  justify-content: space-between;
  border : solid 2px #a4c639;
  border-radius: 10px;
  padding : 5px 5%;
  margin: 10px 5%;
}
.downloadIphone {
  display : flex;
  align-items: center;
  justify-content: space-between;
  border : solid 2px #565656;
  border-radius: 10px;
  padding : 5px 5%;
  margin: 10px 5%;
}
button.loggedbtn{
    /*background-color: orangered;*/
  background-color: #fff4c3;
  color: #52aee6;
  border : 1px solid #f2cb66;
  width: 180px;
  font-weight: 600;
}
button.loggedbtn:hover{
  background-color: #f2cb66;
  color : white;
  border : 1px solid #f2cb66;
}
.myTitle {
  /*position: absolute;*/
  text-decoration: underline;
  /*left : 10%;*/
  margin-left: 10px;
  font-family: 'Poiret One', cursive;
  font-weight: 900;
  min-width: 120px;
  z-index: 20;
  text-shadow: 1px 1px 0px #eee, 1px 1px 2px #707070;
}
.studentName {
  color : #1890e6;
  font-weight: 700;
}

/* Popup container */
.popup {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  /*margin: 20px 0;*/
}

/* The actual popup (appears on top) */
.popup .popuptext {
  /*visibility: hidden;*/

  -webkit-animation: hide-animation 0.6s ease-in 1.5s;
  animation: hide-animation 0.6s ease-in 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

  width: 220px;
  background-color: #f2b436;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  /*margin-left: -180px;*/
  margin: 0 0 -63px -170px;
  /*margin-top : 120px;*/
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: -10px; /*100%;*/
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f2b436 transparent;
  /*border-color: #f2c236 transparent transparent transparent;*/
}
.addRef {
  display: inline-block;
  padding: 8px 0;
}
/* Toggle this class when clicking on the popup container (hide and show the popup) */
/*.popup .show {*/
  /*!*visibility: visible;*!*/
  /*!*-webkit-animation: fadeIn 1s;*!*/
  /*!*animation: fadeIn 1s*!*/

  /*-webkit-animation: hide-animation 0.6s ease-in 0s;*/
  /*animation: hide-animation 0.6s ease-in 0s;*/
/*}*/

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

@-webkit-keyframes hide-animation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes hide-animation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.homeWorkSectionMain {
  /*display : flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*position : relative;*/
  /*margin: 5px;*/
  /*width : 79%;*/
  /*padding : 5px;*/
  /*border: 0.8px solid gray;*/
}

#buttonspnt{
  position: relative;
}

.moveButtons-enter {
   right : 100px;
}
.moveButtons-enter-active {
  right : 0px;
  transition : .5s easy-in;
}
.title-enter {
  opacity: 0.01
}
.title-enter-active {
  opacity: 1;
  transition : easy-in 1s;
}
.descrHeader{
  display:flex;
  justify-content: space-between;
  margin : 5px 10%;
}

.lastRecords {
  margin : 5px 0;
  border-radius: 5px;
  /*padding : 0 5px;*/
  background-color: #fff4c3;
  padding : 5px 10px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  /*font-size : 1em;*/
  /*height: 30px;*/
}
.lastRecords > h4 {
  padding : 5px 0;
}
@media only screen and (max-width: 400px) {
  .lastRecords {
    font-size : 0.8em;
  }
  .descrHeader{
    display:flex;
    justify-content: space-between;
    margin : 3% 5% 0 5%;
  }
}
.loadCursor {
  /*position : absolute;*/
  /*width : 5%;*/
  /*height : 5%;*/
  /*z-index : 200;*/
  /*left : 50%;*/
  /*top : 50%;*/
  /*!*margin : 50%;*!*/
  /*background-color: #7DA8E6;*/
}
.lds-ring {
  display: inline-block;
  position: absolute;
  top : 50%;
  left : 50%;
  width: 5%;
  height: 5%;
  z-index : 20;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #7DA8E6;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #7DA8E6 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.descrAndAnnounceMobile {
  text-align: center;
  display: flex;
  flex-direction: column;
  width : 40%;
}
.descrAndAnnounce {
  text-align: center;
}
.descrHeaderNotMobile {
  display: flex;
  flex-direction: column;
  width : 50%;
  margin-right: 10%;
}
.descrAndAnnounceNotMobile {
  text-align: center;
  display:flex;
  /*width : 100%;*/
  /*margin : 0 10%;*/
  padding : 0 2%;
  justify-content: space-between;
}
.descrAndAnnounceNotMobileMainPage {
  text-align: center;
  display:flex;
  /*width : 100%;*/
  /*margin : 0 10%;*/
  padding : 0 10%;
  justify-content: space-between;
}
.btn-videocam{
  background-color: #1a93c0;
}
.btn-chat, .btn-chat-new, .btn-videocam{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  bottom : 20px;
  width : 60px;
  height: 60px;
  border-radius: 30px;
  z-index: 30;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);

}
.btn-chat-new{
  /*right : 10%;*/
  background-color: #f2b436;
}
.btn-chat{
  left : 10%;
  background-color: #33ccff;
}
.btn-chat:hover, .btn-chat-new:hover, btn-videocam:hover{
  cursor : pointer
}

.mym-new-chat-messages-count{
    position: absolute;
    display : flex;
    /*align-items: center;*/
    justify-content: center;
    right : 0;
    top : 0;
    background-color: var(--selected-item-hard);
    width : 20px;
    height: 20px;
    border-radius: 10px;
    color : white;
    font-size : .8em;
    border : none;
    /*padding-top: -5px;*/
}
.mym-new-chat-messages-hw-count{
    position: absolute;
    display : flex;
    /*align-items: center;*/
    justify-content: center;
    left : 0;
    top : 0;
    background-color: var(--msg-hw-color);
    width : 20px;
    height: 20px;
    border-radius: 10px;
    color : white;
    font-size : .8em;
    border : none;
}
.mym-app-arrow-down{
  color: #4d4d4d;
  font-size: 1em;
}
.mym-app-arrow-up{
  color: #4d4d4d;
  padding: 2px;
  font-size: 1em;
}
.mym-app-button-with-arrow{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.mym-app-button-exit{
  border-radius: 5px;
  padding: 2px;
  text-align: center;
  color: var(--navbar-color);
  font-weight: 600;
}
.mym-app-button-name{
  background-color: var(--navbar-color);
  color: var(--menu-item);
  font-weight: 600;
  border-radius: 7px;
  padding: 2.5px 10px;
}
.mym-app-button-name-small{
  background-color: var(--navbar-color);
  color: var(--menu-item);
  /*font-weight: 600;*/
  border-radius: 7px;
  padding: 1px 10px;
  font-size : 0.7em;
}
.navBlock{
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  max-width: 180px;
  width : 100%;
}
.navBlockEx{
  display: flex;
  justify-content: flex-end;
  /*align-items: center;*/
  width : 100%;
  right : 0;
}
button.mym-login-logged{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.flag-select .flag-options
{
  top : 25px !important;
  margin-top : 0 !important;
}
.app__social-buttons{
  position: fixed;
  display : flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  left: -2px;
  width: 45px;
  height: 200px;
  padding-right: 10px;
  border: 2px solid var(--selected-item);
  background-color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  top: 20%;
  z-index: 3;
}
.app__social-buttons-mobile{
  bottom: 20px;
  position: fixed;
  display: flex;
  padding: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.app__social-button{
  width : 30px;
  height : 30px;
  margin-right: 10px;
  border: .5px solid lightblue;
}
.app__vertical-text{
  -webkit-writing-mode:tb-rl;
          writing-mode:tb-rl;
  -webkit-transform:rotate(360deg);
  transform: rotate(360deg);
  white-space:nowrap;
  display:block;
  /*bottom:0;*/
  /*width:20px;*/
  /*height:20px;*/
}

.menuItemEx {
    display: flex;
    position: relative;
    color: #1890e6;
    align-items: center;
    border : 1px solid var(--selected-item);
    height: 30px;
    padding-left: 10px;
    font-weight: 600;
    border-radius: 10px;
 }
.menuex__menuItemEx-disabled {
    display: flex;
    position: relative;
    color: darkgrey;
    align-items: center;
    border : 1px solid darkgrey;
    height: 30px;
    padding-left: 10px;
    font-weight: 600;
    border-radius: 10px;
}
.mym-app-arrow-down-ex{
     font-size: .8em;
    margin-left: 10px;
}
.menuItemEx a {
    color : #1890e6;
}
.activeItemEx {
    background-color: #7DA8E6;
    color: #eee;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /*border: 2px solid rgb(47,117,181);*/
    /*border-bottom: none;*/
    /*height: 35px !important;*/
}
.activeItemEx2 {
    background-color: #7DA8E6;
    color: #eee;
}
.menuItemEx:hover{
    background-color: #7DA8E6;
    color : white;
    cursor : pointer;
}
.menuItemExDropDown{
    position: absolute;
    display: inline-block;
    width : 200px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #7DA8E6;
    color : white;
    margin-top: 2px;
    /*border: 2px solid rgb(47,117,181);*/
    /*border-top: none;*/
}
.menuItemExDropDown a {
    color : #eee;
}
.menuSubItem {
    display: flex;
    height: 30px;
    align-items: center;
}
.menuSubItem a {
    color : #eee;
    font-weight: 600;
    margin: 10px;
}
.menuSubItem a:hover {
    color : white;
}
.menuSubItem:hover {
    cursor : pointer;
}

.menuItemHw a {
    color : var(--menu-homework);
    font-weight: 600;
    padding: 5px 3px;
}
.menuItemHw a:hover{
    color : rgb(249, 247, 140);
    text-decoration: underline;
    background-color: #7DA8E6;
    height: 30px;
    outline: none;
    border-radius: 10px;
}
.menuBlock{
    margin : 5px;
}
.menuItemHint{
    position: absolute;
    font-size: 0.6em;
    color : #eee;
    background-color: #7DA8E6;
    right : 0;
    top : 0;
}
.menuItems {
    display:flex;
    left : 0;
    margin-left: 10px;
    width : auto;
    min-width: 360px;
    max-width: 600px;
    font-size: .88em;
}
.menuex__emptysubitem {
    position: absolute;
    top: 0;
    height: 40px;
    background-color: #7DA8E6;
    width: 100%;
    border: 1px solid #7DA8E6;
    border-radius: 10px;
    margin-left: -11px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index:-1;
}
.activeItem a {
    box-shadow: 0 0 4px 7px #7DA8E6;
    background-color: #7DA8E6;
    color : white;
    border-radius: 2px;
}
.mym-menuitem-workflow a {
    font-weight: 700;
    color : #B40530;
    font-size : 0.9em;
}
.menuBlock div {
    margin-right : 5px;
}
.mym-menuitem-workflow a:hover{
    text-decoration: underline;
    box-shadow: 0 0 4px 7px #7DA8E6;
    background-color: #7DA8E6;
    color : white;
    border-radius: 2px;
}
.bm-burger-button {
    position:absolute;
}

#page-wrap {
    text-align: center;

    /* Prevent sidebar from showing a scrollbar on page */
    overflow: auto;
}

/* Individual item */
.bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
    color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 50px;
    top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #555a6e;
    /*right : 10px;*/
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
    background: #555a6e;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    left :0;
    /*top : 0;*/
}

.bm-menu-my {
    left : 0;
    top : 0;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
    top : 0;
    background: rgba(0, 0, 0, 0.18);
}
.mym-menu-title {
    border: none;
    font-weight : 700;
    outline: none;
}


.loginBlockLight, .loginBlockLightMobile, .loginBlockLightWithPhone, .loginBlockLightMobileWithPhone{
    display: block;
    position: absolute;
    background-color: var(--selected-item);
    box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.4);
    width: 150px;
    padding : 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.loginBlockLight {
    z-index: 11;
    height: 190px;
}
.loginBlockLightWithPhone {
    z-index: 11;
    height: 225px;
}
.loginBlockLightMobile{
    z-index: 50;
    right: 100px;
    height: 175px;
}
.loginBlockLightMobileWithPhone{
    z-index: 50;
    right: 100px;
    height: 225px;
}
.loginBlockLight input {
    width: 95%;
}
.loginButtons > button {
    margin: 10px 5px 5px 0;
    height: 30px;
    border : 1px solid #686868;
    border-radius: 5px;
    width : 45%;
}
.loginButtons {
    display:flex;
    justify-content: space-between;
    flex-direction: row;
}
.my-google-button-class {
    background-color: #DB4437;
    border: solid 1px #DB4437;
}
.my-facebook-button-class {
    background-color: #3b5998;
    border: solid 1px #3b5998;
}
.my-facebook-button-class:hover,.my-google-button-class:hover {
    opacity : 0.9;
    cursor : pointer;
}
.socialBtns {
    display: flex;
    justify-content: space-between;
    /*width : 100%;*/
    /*padding : 0 5%;*/
}
.socialBtns > button {
    width : 80%;
    margin : 2px;
    padding : -2px;
}
.my-login-btn:hover {
    cursor:pointer;
}
.loginblocklight__notice-pwd {
    font-size: .8em;
    font-weight: bold;
    color: #ddebf7;
    margin: 0 2%;
}
.loginblocklight__notice-pwd.disabled {
     color: #555a6e;
 }
.loginblocklight__notice-pwd.enabled:hover {
    cursor : pointer;
    color: #b52227;
}
.loginblocklight__mail-validated{
    position: absolute;
    top: 3px;
    right: 1px;
    font-size: .6em;
    font-weight: bold;
    color: white;
    background-color: #555a6e;
    border-radius: 13px;
    width: 13px;
    text-align: center;
    height: 13px;
}
@media only screen and (min-width: 400px) {
    .homeWorkSectionMain {
        width : 100%;
    }
    .homeWorkSection { width : 100%; }
    .app-homeWorkSection{
        width : 50%;
        margin : 5px 10px 0 0;
    }
}
@media only screen and (max-width: 400px) {
    .homeWorkSectionMain {        width : 100%;   }
    .myTitle > h3 {font-size : 1em}
    .homeWorkSection { margin : 0 5%; width: 95% }
    .app-homeWorkSection{
        width : 95%;
        /*margin : 5px 10px 0 0;*/
    }
}
/*@media only screen and (min-width: 401px) {    .homeWorkSectionMain {        margin: 55px 0 0 0;    }  }*/

.homeWorkSectionMain {
    position: relative;
    display: flex;
    justify-content: flex-start;
    /*align-items: flex-start;*/
    height: 100%;
    border-radius: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /*left : 0;*/
}

.homeWorkSection {
    display: inline-block;
    /*border : 1px solid green;*/
    /*width : 100%;*/
    margin: 1px;
    /*border-bottom-left-radius: 10px;*/
    /*border-bottom-right-radius: 10px;*/
    border: 0.5px solid #9D9D9D;
    border-radius: 10px;
    /*z-index: 10;*/
    /*left : 0;*/
}
.hwSectionLeft{
    /*background-color: rgb(157, 157, 157);*/
    /*color:white;*/

    color: #686868;
    background-color:white;
    border-top: 0.5px solid #9D9D9D;
    border-left: 0.5px solid #9D9D9D;
    border-right: 0.5px solid #9D9D9D;
    border-bottom: 0.5px solid #9D9D9D;
    /*border : 0.5px solid #9D9D9D;*/
    /*border-radius: 10px;*/
    font-weight: 700;

    /*border : 2px solid rgb(255,230,153);*/
    width: 30%;
    padding : 5px;
    text-align: center;

    /*-moz-border-radius-topleft: 10px;*/
    /*-moz-border-radius-topright: 10px;*/
    border-top-left-radius: 10px;
    /*border-top-right-radius: 10px;*/
    border-bottom-right-radius: 10px;
}
.hwSectionRight{
    /*background-color: rgb(236, 236, 236);*/
    /*border : 1px solid #DDEBF7;*/
    border-top: 0.5px solid #9D9D9D;
    /*border-left: 0.5px solid #9D9D9D;*/
    border-right: 0.5px solid #9D9D9D;
    width: 70%;
    padding : 5px 5px 5px 15px;
    /*color : rgb(181, 34, 39);*/
    /*color : rgb(255, 230, 153);*/
    /*color : white;*/
    color : #9d9d9d;

    /*text-shadow:*/
            /*-0.5px -0.5px 0px #555a6e,*/
            /*0px -0.5px 0px #555a6e,*/
            /*0.5px -0.5px 0px #555a6e,*/
            /*-0.5px  0px 0px #555a6e,*/
            /*0.5px  0px 0px #555a6e,*/
            /*-0.5px  0.5px 0px #555a6e,*/
            /*0px  0.5px 0px #555a6e,*/
            /*0.5px  0.5px 0px #555a6e;*/

    /*text-shadow: 0 0 2px #2F75B5; !* horizontal-offset vertical-offset 'blur' colour *!*/
    /*-moz-text-shadow: 0 0 2px #2F75B5;*/
    /*-webkit-text-shadow: 0 0 2px #2F75B5;*/

    font-size : 1.1em;
    font-weight: 700;
    border-top-right-radius: 10px;
    /*color : rgb(255,230,153)*/
}
.homeWorkSectionHeader {

    background-color: #F5F5F5;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.buttonPlusDay:hover, .buttonMinusDay:hover{cursor: pointer; background-color: rgb(249, 209, 105);}
.buttonPlusDay, .buttonMinusDay
{
    display: flex;
    /*border : 4px solid rgb(235, 197, 99);*/
    border : 1.5px solid #9D9D9D;
    border-radius: 10px;
    /*color : rgb(192,143,0);*/
    color:#9D9D9D;
    justify-content: center;
    align-items: center;
    padding : 2px 20px;
    font-weight: 700;
    height: 30px;
    margin: 2px 2px;
    /*margin: ;*/
}
.homeWorkSectionHeader, .homeWorkSectionSelDate, .homeWorkCurrentSectionHeader, .homeWorkCurrentSectionHeaderL, .homeWorkCurrentSectionHeaderR, .homeWorkSectionSelSubj{
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding : 5px;*/
}
.homeWorkSectionSelDate{
    /*background-color: rgb(255,230,153);*/
    /*background-color: rgb(157, 157, 157);*/
    background-color: #F5F5F5;
    /*color : rgb(192,143,0);*/
    color : white;
    border-right : 0.5px solid #9D9D9D;
    border-left: 0.5px solid #9D9D9D;
}
.weekDay {
    font-weight: 700;
    /*color :#52aee6;*/
    color : #95c3d3;
}
.hwDate {
    /*color : rgb(192,143,0);*/
    color : #2F75B5;
    font-weight: 700;
}
.homeWorkCurrentSectionHeader{
    background-color: rgb(47,117,181);
    color: white;
}
.homeWorkCurrentSectionHeaderL{
    background-color: rgb(221, 235, 247);
    color: white;
}
.homeWorkCurrentSectionHeaderR{
    /*background-color: rgb(47,117,181);*/
    color: white;
}
.hwCurrentSectionLeft,.hwCurrentSectionRight{
    width: 50%;
    padding : 5px;
    font-weight: 700;
    text-align: center;
    /*-moz-border-radius-topleft: 10px;*/
    /*-moz-border-radius-topright: 10px;*/
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
}
.hwCurrentSectionRight {
    background-color: rgb(221, 235, 247);
    color: rgb(47, 117, 181);
    /*-moz-border-radius-topright: 10px;*/
    border-top-left-radius: 10px;
    /*border-top-right-radius: 10px;*/
}
.hwCurrentSectionLeft {
    /*background-color: rgb(47, 117, 181);*/
    /*background-color: rgb(255, 230, 153);*/
    background-color: white;
    color: rgb(47, 117, 181);
    /*color: white;*/
    /*-moz-border-radius-topleft: 10px;*/
    /*border-top-left-radius: 10px;*/
    border-top-right-radius: 10px;
}
.hwCurrentSectionLeft:hover{
    cursor:pointer;
    /*color : rgb(255,230,153)*/
    /*color : white;*/
    color: rgb(47, 117, 181);
}
.hwCurrentSectionRight:hover{
    cursor:pointer;
    /*color : rgb(192,143,0)*/
    /*color : white;*/
    color: rgb(47, 117, 181);
}
.leftSideSubjList, .rightSideSubjList{
    display: inline-block;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /*border-left : 1px solid rgb(47,117,181);*/
    /*border-right : 1px solid rgb(47,117,181);*/
    /*border-bottom : 1px solid rgb(47,117,181);*/
}
.leftSideSubjList{
    backround-color: white;
    color: rgb(140, 183, 198);
    /*padding-bottom: 2px;*/
    /*background-color: rgb(47,117,181);*/
    /*color: white;*/
}
.rightSideSubjList{
    background-color: rgb(221,235,247);
    color: rgb(47,117,181);
}
.listOfHomeWork{
    border : 1px solid rgb(47,117,181);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /*background-color: #fff;*/

    /*background-color: rgb(47,117,181)*/
    background-color: white;
}
.factSubjRow {
    display: flex;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid rgb(157, 206, 223);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.subjName {
    display: inline-block;
    font-weight: 700;
    padding : 2px 5px;
    width: 30%;
    /*text-align: center;*/
}
.subjNameTitle {
    font-weight: 700;
    padding : 2px 5px;
    width: 100%;
    text-align: center;
}
.subjHomeWork{
    display: inline-block;
    margin: 0 5px;
    color : #686868;
    width: 70%;
    font-weight: 600;
    /*text-align: right;*/
}
.imgEdit > img {
    position: absolute;
    right : 2px;
    top : 2px;
    width : 15px;
    height: 15px;
    z-index: 3;
    background-color: lightblue;
    border-radius: 3px;
    opacity : 0.6;
}
.buttonPlusSubj:hover, .buttonMinusSubj:hover{cursor: pointer; background-color: rgb(221,235,247);}
.buttonPlusSubj, .buttonMinusSubj
 {
     display: flex;
     border : 2px solid rgb(157, 206, 223);
     border-radius: 10px;
     color : rgb(47,117,181);
     justify-content: center;
     align-items: center;
     padding : 2px 20px;
     font-weight: 700;
     height: 30px;
     margin : 2px 2px;
 }
.buttonMinusFaked
{
    display: flex;
    visibility: hidden;
    border : 2px solid rgb(157, 206, 223);
    border-radius: 10px;
    color : rgb(47,117,181);
    justify-content: center;
    align-items: center;
    padding : 2px 20px;
    font-weight: 700;
    height: 30px;
    margin : 2px 0;
}
.homeWorkSectionSelSubj{
    background-color: rgb(221,235,247);
    color : rgb(47,117,181);
}
.mainInput {
    display: inline-block;
    margin : 1px 2px 0 2px;
    height: 100px;
    width: 97%;
    font-size: 1.2em;
}
.addHomeWorkBtn {
    text-align: center;
    padding: 5px;
    border: 1px solid  rgb(47,117,181);
    background-color: rgb(255,230,153);
    border-radius: 10px;
    font-weight: 700;
    color : rgb(192,143,0);
    width: 100%;
}
.buttonsSection {
    display: flex;
    justify-content: space-between;
    padding : 2px;
    position: relative;
}
.symbButton {
    display: inline-block;
    border : 1px solid rgb(47,117,181);
    background-color: rgb(221,235,247);
    color : rgb(47,117,181);
    border-radius: 3px;
    margin : 1px;
    padding : 2px;
    text-align: center;
    width : 43px;
    font-weight: 800;
    /*height : 20px;*/
}
.btnSpace, .btnComa, .btnBackSpace  {
    display: inline-block;
    border : 1px solid rgb(47,117,181);
    background-color: rgb(221,235,247);
    color : rgb(47,117,181);
    border-radius: 3px;
    margin : 1px;
    padding : 2px;
    text-align: center;
    width : 70%;
    margin-bottom: 14px;
    font-weight: 800;
    /*height: 20px;*/
}
.btnSpace{    width : 70%; }
.btnComa{    width : 10%;}
.btnBackSpace {    width: 20%; color : #b52227; font-size: 1.2em}
.symbButton:hover{
    cursor: pointer;
}
.selectedRow {
    border : 1px solid #FFE699;
    box-shadow: 0px 0px 3px 3px #FFE699;
    border-radius: 4px;
}
.hwInfo{
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    height: 25px;
    padding : 2px;
    width : 97%;
    border-radius: 3px;
    border : 1px solid rgb(47,117,181);
    background-color: rgb(221,235,247);
    color : rgb(47,117,181);
    margin: 1%;
}
/*
.navbar {
    display:flex;
    padding : 0px 10px;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    background-color: #f0f0f0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
}
*/

.navbar-shadow {
    position: relative;
    top: 0; /* Position the navbar at the top of the page */
    width: 100%;
    height: 50px;
}
@media only screen and (max-width: 400px) {
    #rangeStatus {
        font-size : 0.6em;
    }
    .selectGroup {
        margin: 0;
    }
    .mainChartSection{
        margin: 0;
    }
}
@media only screen and (max-width: 400px) {
    .chat-container {
        width : 90%;
    }
    .msg-title-userdata-email {
        width : 50%;
    }
}
@media only screen and (min-width: 400px) {
    .msg-title-userdata-email {
        width : 180px;
    }
}
.msg-list { margin-bottom : 0; margin-top : 5px }

.chat-container, .chat-container-new {
    position: absolute;
    border: 0.5px solid var(--border-color);
    width : 80%;
    /*max-width:370px;*/
    min-height: 70%;
    bottom : 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 var(--border-color);
    z-index : 40;
    background-color: white;
    opacity : 0;
    transition: opacity .3s ease-in-out;
}
.chat-container-new {
    /*float: right;*/
    margin-right: 5%;
    right : 5%;
}
.chat-container {
    /*float: left;*/
    margin-left: 5%;
    left : 5%;
}
.msg-left-side,.msg-right-side {
    position : relative;
    display: flex;
    flex-direction: column;
    padding : 13px 10px;
    width : 88%;
    margin-top : 5px;
    margin-bottom : 2px;
    border-radius: 10px;
}
.msg-left-side{
    background-color: var(--msg-left-side);
}
.msg-right-side{
    background-color: var(--msg-right-side);
}
.msg-left-side > div.msg-author{
    margin : -15px -50px -15px 0;
}
.msg-left-side{
    float:left;
    text-align: left;
}
.msg-right-side{
    float:right;
    text-align: left;
}
.message-list{
    /*border : 1px solid darkgrey;*/
    border-radius: 10px;
    margin : 12px 10px;
    height:60vh;
    max-height:510px;
    overflow: auto;
    /*width: 90%;*/
}
.message-block{
    display: inline-block;
    width: 100%;
    position: relative;
    font-size: 0.9em;
    padding: 0;
    /*border : 2px solid white;*/
}
.message-block-edit-left, .message-block-edit-right {
    position: absolute;
    resize: none;
    display:block;
    font-size: 1.3em;
    width: 100%;
    height: 85%;
    border : solid 2px var(--selected-item);
    background-color: var(--msg-right-side);
    top : -2px;
    outline: none;
    border-radius: 10px;
}
.message-block-edit-left{
    left : 0;
}
.message-block-edit-right{
    right : 0;
}

.msg-text {
    font-size : .95em;
    font-weight : 500;
}
.msg-right-author, .msg-left-author {
    color : white;
    display: inline-block;
    position: absolute;
    /*background-color: white;*/
    border-radius: 8px;
    padding : 0 7px 2px 7px;
    font-size: 0.75em;
    margin : -17px 0 0;
    border: 0.5px solid var(--msg-header-color);
    background-color: var(--msg-header-color);
    font-weight: 600;
}
.msg-right-author{
    right : 10px;
}
.msg-left-author{
}
.msg-right-ishw, .msg-left-ishw {
    color : white;
    display: inline-block;
    position: absolute;
    /*background-color: white;*/
    border-radius: 8px;
    padding : 0 7px 2px 7px;
    font-size: 0.75em;
    margin : -17px 0 0;
    border: 0.5px solid var(--msg-hw-color);
    background-color: var(--msg-hw-color);
    font-weight: 600;
}
.msg-right-ishw{

}
.msg-left-ishw{
    right : 10px;
}
.msg-title, .msg-title-new {
    display:flex;
    justify-content: space-between;
    background-color: var(--msg-header-color);
    padding : 15px 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: white;
    font-weight: 600;
    font-size: 1em;
}
.msg-title-new {     background-color: var(--msg-header-color-new); }
.msg-title {     background-color: var(--msg-header-color); }

.msg-title-userdata {
    display:flex;
    justify-content: space-between;
    background-color: var(--msg-header-color);
    /*padding : 0 10px 10px;*/
    padding : 10px;
    color: white;
    font-weight: 600;
    font-size: 1em;
}
.msg-title-userdata-name {
    width : 80px;
}
/*.msg-title-userdata-email {*/
    /*width : 180px;*/
/*}*/
.msg-title:hover {
    cursor : pointer
}
.msg-add-textarea{
    /*border : 0.5px solid darkgrey;*/
    border : none;
    margin : 8px;
    width: 80%;
    height : 15px;
    padding : 5px;
    /*background-color: #e9e9e9;*/
    background-color: white;
    resize: none;
    outline: none;
    border-radius: 10px;
}
.btn-add-message{
    display: inline-block;
    /*border : 0.5px solid darkgrey;*/
    /*background-color: lightgrey;*/
    background-color: #e9e9e9;
    text-align: center;
    height: 25px;
    margin : 5px;
    padding : 2px 4px;
    border-radius: 5px;
}
.btn-add-message:hover{
    cursor: pointer;
}
.frm-add-msg {
    display: flex;
    flex-direction: column;
    background-color: #e9e9e9;
    border : 0;
    margin : 0 1px;
    padding : 5px 0;
    /*height: 80px;*/
    height : auto;
    bottom : 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.input-msg-block{
    display: flex;
    position: relative;
    justify-content: space-between;
    width : 100%;
}
.btn-add-time, .btn-add-time-done  {
    position : absolute;
    /*top: -2px;*/
    right : 5px;
    bottom : 2px;
    float : right;
    font-size : 0.7em;
}
.btn-add-time-done {    color : #526ef3; }
.btn-add-time {    color : darkgrey; }
.add-msg-container{
    display: flex;
    flex-direction: column;
    position : relative;
    height: auto;
    /*height: 80px;*/
}
.add-msg-homework-block{
    /*position : absolute;*/
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border : 0.5px solid darkgrey;
    border-radius: 10px;
    margin : 0 5px;
    height : 45px;
    /*bottom : 5px;*/
    width : 97%;
}
.homework-plus{
    position : absolute;
    right : 5px;
    top : -22px;
    font-weight: 800;
    background-color: var(--msg-header-color);
    color: #f7f7f7;
    border: 2px solid #898989;
    padding : 1px 5px 3px;
    text-align: center;
    border-radius: 12px;
    font-size: .9em;
    display: inline-block;
    width : 100px;
}
.homework-plus:hover{
    cursor: pointer;
}
.service-plus{
    position : absolute;
    right : 5px;
    top : 37px;
    font-weight: 800;
    background-color: var(--msg-header-color);
    color: #f7f7f7;
    border: 2px solid #898989;
    padding : 1px 5px 3px;
    text-align: center;
    border-radius: 12px;
    font-size: .9em;
    display: inline-block;
    width : 180px;
    z-index : 20;
}
.service-plus:hover{
    cursor: pointer;
}
.add-msg-homework-title{
    margin-top : -20px;
    margin-left : 10px;
    position : absolute;
    float:left;
    padding : 0 5px;
    font-size: 0.7em;
    background-color: #e9e9e9;
    color : darkgrey;
    /*border : .5px solid black;*/
}
.add-msg-homework-day, .add-msg-homework-subject, .add-msg-homework-user {
    display:flex;
    position : relative;
    border : 1px solid darkgrey;
    padding : 3px 4px 3px 10px;
    margin : 4px 5px 0 5px;
    font-size: .9em;
    color : var(--msg-header-color);
    border-radius: 10px;
    z-index : 2;
    font-weight: 800;
}
.add-msg-homework-day:hover, .add-msg-homework-subject:hover, .add-msg-homework-user:hover{
    cursor : pointer;
}
.btn-close-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius : 10px;
    height : 20px;
    width : 20px;
    background-color: white;
    color : var(--msg-header-color);

}
.btn-close-chat:hover{
    cursor : pointer;
}
.active-msg-btn {
    background-color: var(--msg-header-color);
    color : white;
    /*color : var(--msg-header-color);*/
    border : 1px solid var(--msg-header-color);
}
.msg-btn-up{
    margin : 0 0 0 5px;
    z-index : 2;
    /*background-color: white;*/
    /*border: 0.5px solid #33ccff;*/
    padding : 0 0 0 5px;
    border-radius: 10px;
}
.msg-btn-up:hover{
    cursor: pointer;
}
.showSubjSection, .showDaysSection{
    display : flex;
    position : absolute;
    flex-direction: column;
    opacity: 0;
    height: auto;
    width : 200px;
    border-radius: 10px;
    background-color: #e9e9e9;
    border: 1.5px solid var(--msg-header-color);
    bottom : 30px;
    left : 0;
    z-index : 10;
    transition: opacity .3s ease-in-out;
    padding-bottom: 5px;
}
.homework-border {
    border : 2px solid var(--msg-hw-color-light);
}
.homework-no-border {
    border : 2px solid white;
}
.rta {
    flex-grow: 1;
}

.emoji-mart {
    position: absolute;
    bottom: 20px;
    right: 10px;
}

.message-input:focus {
    outline: none;
}

.toggle-emoji {
    border: none;
    width: 50px;
    height: auto;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
}

.toggle-emoji svg {
    width: 28px;
    height: 28px;
}

.picker-background{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /*text-align: center;*/
    /*box-sizing: border-box;*/
    background-color: white;
    z-index: 29;
    height : 420px;
    width : 360px;
    left : 10px;
    top : 2px;
    border-radius: 5px;
    border: 2px solid grey;
    padding: 5px;
    /*padding: 5px;*/
}

.message-form, .message-input {
    width: 100%;
    margin-bottom: 0;
}

.rta {
    flex-grow: 1;
}

.emoji-mart {
    position: absolute;
    bottom: 20px;
    right: 10px;
}

textarea.message-input {
    /*height: 50px;*/
    flex-grow: 1;
    /*line-height: 35px;*/
    padding-left: 5px;
    border-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    font-size: 1.2em;
    color: #333;
    min-height: auto;
    overflow-y: hidden;
    resize: none;
    /*border-left: 1px solid #ccc;*/
}

.message-input:focus {
    outline: none;
}
.who-typing{
    margin : 0 0 0 20px;
    font-size : .9em;
    color : darkgrey;
    font-weight: 500;
    height: 20px;
    width : 100%;
}
/* RTA
       ========================================================================== */

.rta {
    position: relative;
    border-left: 1px solid #ccc;
    display: flex;
    flex-direction: column;
}

.rta__autocomplete {
    position: absolute;
    width: 150px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.rta__autocomplete ul {
    list-style: none;
    text-align: left;
    margin-bottom: 0;
}

.rta__autocomplete li {
    margin-bottom: 5px;
    padding: 1px 10px;
    cursor: pointer;
}

.rta__autocomplete li:hover {
    background-color: skyblue;
}

.mym-msg-block-buttons{
    position: absolute;
    /*left : 80%;*/
    top : -5px;
    right : 0;
    width : 120px;
    height: 120%;
}
.mym-msg-block-save:hover, .mym-msg-block-delete:hover, .mym-msg-block-cancel:hover, .mym-msg-block-hw:hover{
    cursor: pointer;
}
.mym-msg-block-save, .mym-msg-block-delete, .mym-msg-block-cancel, .mym-msg-block-hw{
    position: absolute;
    padding : 1.5px 4px;
    font-size: .8em;
    width : 60px;
    margin: 4px 0;
    text-align: center;
    /*background-color: var(--selected-item-hard);*/
    color : white;
    border-radius: 7px;
}
.mym-msg-block-save{
     top : 2px;
     right : 0;
     background-color: var(--selected-item-hard);
 }
.mym-msg-block-cancel{
    bottom : 3px;
    right : 0;
    background-color: var(--erase-mark);
    border: .5px solid var(--selected-item-hard);
    color : var(--selected-item-hard);
}
.mym-msg-block-delete{
    bottom : 3px;
    right : 80px;
    background-color: var(--erase-mark);
    border: .5px solid var(--selected-item-hard);
    color : var(--selected-item-hard);
}
.mym-msg-block-hw{
    top : 2px;
    right : 80px;
    background-color: var(--msg-hw-color);
}
.mym-msg-date-separator {
    color: var(--selected-item);
    background-color : var(--selected-item-hover);
    text-align: center;
    padding : 5px;
    margin : 0 30%;
    border : 1px solid var(--selected-item-hover);
    border-radius: 10px;
    font-weight: 600;
}
.loginSection {
    margin : 10px 10%;
    text-align: left;
    float: none;}

.inputBlock input{
    margin: 5px;
    /*height: 20px;*/
}
/*.logBtnsBlock{*/
    /*margin-top : 10px;*/
    /*margin-bottom: 10px;*/
    /*margin-left: 20px;*/
/*}*/
.btn-login-save {
    border-radius: 3px;
    background-color: lightblue;
    border : 1px solid lightblue;
    padding: 5px;
    height: 25px;
    font-weight: 600;
    width : 124px;
    margin : 4px 4px 2px;
    /*margin: 0 40px 0 0;*/
}
.btn-login-save:hover {
    cursor: pointer;
    color : #4472C4;
}
.loginSection ol {
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
/*.loginSection div {*/
    /*!*margin: 2px 10px 2px 4px;*!*/
/*}*/
.loginSubSection {
    display: flex;
    justify-content: space-between;
}
.loginSection ol {
    margin : 0 0 10px 0;
}
.loginSection li {
    margin : 2px 0 5px -10px;
}
.loginSubSection {
    border: 1px solid lightgrey;
    border-radius: 3px;
}
.inputBlock {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    padding : -5px 1px;
}
.inputBlockBtns {
    display: flex;
    position: relative;
    flex-direction: column;
    /*align-items: flex-end;*/
    padding : 5px;
    margin : 0 5px;
}
.inputBlock input {
    width : 120px;
}
.inputBlockTop {
    display : flex;
    flex-direction: column;
    border : 1px solid lightgrey;
    border-radius: 5px;
    padding : 0 5px;
    align-items: center;
    justify-content: center;
    margin : 5px 10px;
}
.inputBlockTop2 {
    display : flex;
    /*flex-direction: column;*/
    /*border : 1px solid lightgrey;*/
    /*border-radius: 5px;*/
    padding : 0 5px;
    align-items: center;
    justify-content: center;
    margin : 5px 10px;
}
.popup3 {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    /*margin: 20px 0;*/
}

/* The actual popup (appears on top) */
.popup3 .popuptext3 {
    /*visibility: hidden;*/

    -webkit-animation: hide-animation 0.6s ease-in 1.5s;
    animation: hide-animation 0.6s ease-in 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

    width: 220px;
    background-color: #f2b436;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    /*margin-left: -180px;*/
    margin: 0 0 -33px -130px;
    /*margin-top : 120px;*/
}

/* Popup arrow */
.popup3 .popuptext3::after {
    content: "";
    position: absolute;
    top: -10px; /*100%;*/
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #f2b436 transparent;
    /*border-color: #f2c236 transparent transparent transparent;*/
}

@-webkit-keyframes hide-animation {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes hide-animation {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}
.btn-social{
    opacity : 0.7
}
.btn-social:hover{
    cursor: pointer;
    opacity: 1;
}
.my-facebook-button-class,.my-google-button-class {
    color : white;
    padding : 0 10px;
    border-radius: 5px;
    height: 36px;
    margin : 3px 0;
    text-align: left;
    width : 100%;
}
.my-google-button-class {
    background-color: #DB4437;
    border: solid 1px #DB4437;
}
.my-facebook-button-class {
    background-color: #3b5998;
    border: solid 1px #3b5998;
}
.my-facebook-button-class:hover,.my-google-button-class:hover {
    opacity : 0.9;
    cursor : pointer;
}
.fbLoginBlock {
    display: flex;
    align-items: center;
    flex-direction: row;
    color : white;
    background-color : #3b5998;
    font-weight: 600;
    border : 2px solid #3b5998;
    border-radius: 5px;
    padding : 3px 10px;
    margin : 3px 0;
}
.fbLoginBlock > div {
    margin : 0 5px;
}
.fbLoginBlock:hover {
    cursor: pointer;
    background-color: #8b9dc3;
    border : 2px solid #8b9dc3;
    color : white;
}
.loginBlockHeader {
    display:flex;
    margin : 0 10px;
    justify-content: space-between;
}
@media only screen and (max-width: 400px) {
    .loginSection {
        margin : 5px 5%;
        font-size : 0.8em;
    }
    .inputBlockTop2 {
        margin : 0;
    }
    .inputBlockTop {
        margin : 3px;
    }
    .inputBlock input {
        width : 90px;
    }
    .btn-login-save {
        width : 94px;
    }
    .loginSubSection {
        flex-direction: column;
    }
}
.subjFromSelector, .subjToSelector {
    width : 170px;
    height : 530px;
}
.subBlocks {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    /*margin: 10px 10%;*/
    border : 1px lightgrey solid;
    padding : 2px;
    height : 580px;
}
.subBlocks > div{
    margin: 2px;
}
.h4 {
    font-weight: 600;
    padding : 5px;
    /*margin-bottom: 5px;*/
}
.tableStats{
    /*margin: 10px 10%;*/
    /*width: 100%;*/
}
/*{name: "Дата", width : "80"} ,*/
/*{name: "Час", width : "20"},*/
/*{name: "Начало", width : "180"},*/
/*{name: "Конец", width : "180"},*/
/*{name: "Минут", width : "20"},*/
/*{name: "Оценок", width : "20"},*/
/*{name: "Оценок/в мин", width : "100%"},*/
.colstat-0 {width: 10%;}
.colstat-1 {width: 5%;}
.colstat-2 {width: 15%;}
.colstat-3 {width: 15%;}
.colstat-4 {width: 5%;}
.colstat-5 {width: 5%;}
.colstat-6 {width: 5%;}

.mym-adminpage-container{
    margin: 10px 0;
}
.mym-adminpage-subjblock {
    display: flex;
    flex-direction: column;
}
.mym-adminpage-subjblock-selectors {
    display: flex;
    flex-direction: row;
}
.mym-adminpage-subjblock {
    display: flex;
    flex-direction: column;
}
.mym-adminpage-translateblock {
    width : 100%;
}
.mym-adminpage-translateblock-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.mym-btn-add-lang-alias {
    border: 2px solid var(--selected-item-hard);
    text-align: center;
    border-radius: 5px;
    background-color: lightgrey;
    width : 80px;
    height : 25px;
}
.mym-btn-add-lang-alias:hover {
    background-color: var(--erase-mark);
    cursor: pointer;
}
.mym-adminpage-subjblock-header {
    background-color: var(--selected-item-background);
    border-radius: 5px;
    padding : 3px;
    margin-bottom: 5px;
}
.mym-adminpage-translateblock-header {
    background-color: var(--selected-item-background);
    border-radius: 5px;
    padding : 3px;
    margin-bottom: 5px;
}
table {
    width : 100%;
    table-layout: fixed;
}
.left-text {
    text-align: left;
}
.center-text> input[type="checkbox"]{
    margin: 0 40%;
}
th {
    /*height: 30px;*/
}
td {
    position: relative;
    font-size : 1em;
}
.inputEditor {
    position : absolute;
    height: 90%;
    width : 99%;
    border : 1px solid #52aee6;
    left : 0;
    top : 0;
}
tr#row-1, tr#row-2{
    background-color: lightgrey;
}
.verification{
    background-color: rgb(255,235,156);
}
.verified{
    background-color: rgb(198,239,206)
}
.mym-universaltable-container {
    position : relative;
    overflow : hidden;
    /*overflow : overflox-x;*/
    /*height: 500px;*/
}
.mym-btn-add-lang-alias, .mym-show-saldo {
    position : absolute;
    top : -3px;
    border: 2px solid var(--selected-item-hard);
    text-align: center;
    border-radius: 5px;
    width : auto;
    padding : 0 10px;
    height : 20px;
    margin : 3px;
    font-size: .9em;
    z-index : 2;
    color : white;
}
.mym-btn-add-lang-alias{
    left : 3%;
    background-color: var(--selected-item);
}
.mym-show-saldo{
    left : 120px;
    background-color: var(--menu-item);
}
.mym-btn-add-lang-alias:hover {
    background-color: var(--erase-mark);
    color : var(--selected-item-hard);
    cursor: pointer;
}
.rotate {
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /*width: 1.5em;*/
}
.rotate div {
    -moz-transform: rotate(-90.0deg);  /* FF3.5+ */
    -o-transform: rotate(-90.0deg);  /* Opera 10.5 */
    -webkit-transform: rotate(-90.0deg);  /* Saf3.1+, Chrome */
    filter:  progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083);  /* IE6,IE7 */
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)"; /* IE8 */
    margin-left: -1em;
    margin-right: -1em;
    padding : 25px 0;
}
.plusSaldo {
    position : absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right : 1px;
    top : -3px;
    border-radius : 9px;
    height : 15px;
    width : 15px;
    /*text-align: center;*/
    /*font-weight: 800;*/
    font-size: 1em;
    color : white;
    border : 0;
    line-height: 200px;
    /*white-space: nowrap;*/
    /*vertical-align: middle;*/
    /*padding-top: -5px;*/
    background-color: #55b162;
}
.mym-adminpageteacher-tableblock {
    margin : 5px 0;
}
.mym-adminpageteacher-description {
    position: absolute;
    margin : 5px 2%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width : 100%;
    right : 20px;
}
.addToSchool {
    border : 1px solid var(--selected-item);
    padding : 5px;
    background-color: var(--selected-item);
    color : var(--msg-left-side);
    font-weight: 600;
    border-radius: 5px;
}
.addToSchoolDisabled {
    border : 1px solid var(--selected-item);
    padding : 5px;
    background-color: var(--navbar-color);
    color : var(--border-color);
    font-weight: 600;
    border-radius: 5px;
}
.addToSchool:hover{
    color : white;
    cursor : pointer;
}
.addUserButton{
    border : 1px solid var(--selected-item-background);
    background-color: var(--selected-item-hard);
    color : var(--msg-left-side);
    font-weight: 300;
    border-radius: 3px;
    right : 0;
    top : 0;
    bottom: 0;
    position: absolute;
    height: 22px;
    margin : 2px;
    padding : 0 2px;
    /*margin-right: 20px;*/
}
.addUserButton:hover{
    right : 0;
    background-color: var(--selected-item);
    cursor : pointer;
}
.flexTD{
}
.changeStudentButton {
    background-color : #1890e6;
    color : #fff;
    border-radius : 5px;
}
.changeStudentButton:hover {
    background-color : #33ccff;
    cursor : pointer;
}
.menuItem a {
    color : #1890e6;
    font-weight: 600;
    padding: 5px 3px;
    /*font-size : 0.9em;*/
}
.menuItem a:hover{
    /*color : #375c9e;*/
    text-decoration: underline;
    /*-webkit-box-shadow: 0 0 4px 7px #7DA8E6;*/
    /*-moz-box-shadow: 0 0 4px 7px #7DA8E6;*/
    /*box-shadow: 0 0 4px 7px #7DA8E6;*/
    background-color: #7DA8E6;
    color : white;
    height: 30px;
    outline: none;
    border-radius: 10px;
    /*border-radius: 2px;*/
}
.menuItemHw a {
    color : var(--menu-homework);
    font-weight: 600;
    /*font-size : 0.9em;*/
    padding: 5px 3px;
}
.menuItemHw a:hover{
    color : rgb(249, 247, 140);
    text-decoration: underline;
    /*-webkit-box-shadow: 0 0 4px 7px #7DA8E6;*/
    /*-moz-box-shadow: 0 0 4px 7px #7DA8E6;*/
    /*box-shadow: 0 0 4px 7px #7DA8E6;*/
    background-color: #7DA8E6;
    /*color : white;*/
    /*border-radius: 2px;*/
    /*padding: 5px 3px;*/
    height: 30px;
    outline: none;
    border-radius: 10px;
}
.menuBlock{
    margin : 5px;
}
.menuItems {
    display:flex;
    left : 0;
    margin-left: 10px;
    width : auto;
    min-width: 360px;
    max-width: 600px;
    font-size: .88em;
}
.menuBlock div {
    margin-right : 5px;
}
.activeItem a {
    box-shadow: 0 0 4px 7px #7DA8E6;
    background-color: #7DA8E6;
    color : white;
    border-radius: 2px;
}
.mym-menuitem-workflow a {
    font-weight: 700;
    color : #B40530;
    font-size : 0.9em;
}
.mym-menuitem-workflow a:hover{
    text-decoration: underline;
    box-shadow: 0 0 4px 7px #7DA8E6;
    background-color: #7DA8E6;
    color : white;
    border-radius: 2px;
}
.schoolSelectedBlock {
    position: relative;
    margin : 2px 2%;
    border-radius: 3px;
    border: 1px solid var(--selected-item);

}
.schoolFilters {
    display: flex;
    justify-content: space-between;
    padding : 12px 10px 2px 10px;
}
.schoolBlock-count{
    position: absolute;
    top : -1px;
    right : 10px;
    font-size: .7em;
    font-weight: 600;
    color : white;
    background-color : var(--selected-item);
    padding : 0 5px;
}
.schlist-label{
    position : absolute;
    left : 0;
    top : 0;
    color : var(--selected-item-hard);
    font-size: 0.65em;
    font-weight: 600;
}
.subjectsByDayOfWeek {
    /*border : 1px solid #000;*/
    display: flex;
    flex-wrap: wrap;
    /*padding-right : 10px;*/
    /*padding-bottom: 10px;*/
    width : 60%;
}
.timetableMain {
    display : flex;
    /*padding-right : 15px;*/
}
.subjListTableBlock{
    margin-right: 10px;
    margin-bottom: 10px;
}
.timeTableSelected{
    background-color: var(--selected-item-background);
}
.lockTimetable-btn {
    border: 2px solid #b52227;
    border-radius: 5px;
    font-size: .8em;
    padding: 5px;
    text-align: center;
    background-color: #bdd7ee;
    width: 40%;
    margin-left: 5%;
}
.lockTimetable-btn:hover{
    cursor: pointer;
    background-color: #70baf7;
    color : white;
}
.timetable__subj_name-col{
    font-size : .8em;
    font-weight : bold;
    color : #2f75b5;
}
/*html, body {*/
    /*height: 100%;*/
    /*margin: 0;*/
/*}*/
.mym-wf-main-title{
    margin : 10px 5% 20px;
}
.mym-wf-main-block {
    position: relative;
    display : flex;
    flex-direction: row;
    margin : 5px 5% 20px;
    border : .5px solid grey;
    width : 90%;
    height: 90vh;
    min-height: 90vh;
    max-height: 90vh;
    overflow: auto;
}
.mym-wf-left-block {
    width : 70%;
    /*border : .5px solid #a3eaf7;*/
    height: 100%;
}
.mym-wf-right-block {
    display : flex;
    position: relative;
    flex-direction: column;
    width: 30%;
    /*border : .5px solid blue;*/
}
.mym-wf-workdescr {
    position: relative;
    height: 60%;
    min-height: 200px;
    /*border : solid .5px darkred;*/
}
.mym-wf-workdescr-content{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.mym-wf-workdescr-select{
    position: absolute;
    bottom : 0;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.mym-wf-workdescr-select select{
    margin-right: 20px
}
.mym-wf-filter {
    height: 40%;
    display: flex;
    position: relative;
    flex-direction: column;
}
.mym-wf-filter-type, .mym-wf-filter-status {
    height: 50%;
}
.mym-wf-filter-header{
    display: flex;
    font-size: .9em;
    justify-content: space-between;
    margin-right: 5%;
}
.mym-wf-filter-header-bottom {
    display: flex;
    font-size: .9em;
    justify-content: flex-end ;
    margin-right: 5%;
}
.mym-wf-workflow-list{
    display:flex;
    flex-direction : column;
    max-height: 100%;
    overflow: auto;
}
.mym-wf-workflow-item{
    background-color: white;
    border-bottom: 1px solid lightgrey;
    width : 100%;
}
.mym-wf-workflow-active{
    background-color: var(--selected-item);
    color : white;
}
.mym-wf-workflow-list:hover{
    cursor: pointer;
}
.mym-wf-workflow-row{
    position : relative;
    display: flex;
}
.mym-wf-workflow-row.mym-wf-workflow-active {
    border: 1px solid var(--selected-item-hard);
}
.mym-wf-workflow-rowno {
    display: flex;
    width : 50px;
    /*text-align: center;*/
    /*vertical-align: middle;*/
    align-items: center;
    justify-content: center;
    font-weight: 900;
    /*padding: 1%;*/
    border-bottom: 1px solid whitesmoke;
}
.mym-wf-workflow-done {
    position: relative;
    background-color: var(--excel-mark);
}
.mym-wf-workflow-doing {
    position: relative;
    background-color: var(--good-mark);
}
.mym-wf-workflow-plan {
    position: relative;
    background-color: var(--norm-mark);
    color : var(--mark-text);
}
.mym-wf-right-block-title{
    display: flex;
    justify-content: space-between;
    margin: 5px;
}
.mym-wf-workdescr-edit{
    position: absolute;
    z-index: 2;
    resize: none;
    display:block;
    font-size: 1.3em;
    width: 98%;
    height: 80%;
    border : solid 1px var(--selected-item);
    /*border: none;*/
    outline: none;
    border-radius: 3px;
    /*margin-bottom: 10px;*/
}
.mym-wf-type {
    position : absolute;
    top : 2px;
    right : 2px;
    background-color: var(--selected-item-hard);
    padding : 3px;
    color : white;
    font-size: 0.7em;
    border-radius: 4px;
    opacity: 1;
    font-weight: 800;
}
.mym-wf-type.mym-wf-type-error {
    color : var(--mark-text);
}
.mym-wf-type.mym-wf-type-future {
    color : darkblue;
}
.mym-wf-type.mym-wf-type-nearest {
    color : var(--msg-header-color-new);
}
.mym-wf-modified {

 }
.insAndOutsBlock {
    display: flex;
    width : 100%;
    height : 20%;
    margin : 10px 0;
}
.insAndOutsFactBlock {
    display: flex;
    width : 100%;
    margin : 10px 0;
}
.insBlock{
    border : 1px solid #2f75b5;
    display: inline-block;
    width : 80%;
}
.outsBlock{
    border : 1px solid #588f62;
    display: inline-block;
    width : 80%;
}
.insFactBlock{
    border : 1px solid #2f75b5;
    /*display: inline-block;*/
    width : 100%;
}
.outsFactBlock{
    border : 1px solid #588f62;
    display: inline-block;
    width : 10%;
}
.budget-rightArrow {
    position : absolute;
    top : 0;
    right : 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 44px 12px 0;
    border-color: transparent #588f62 transparent transparent;
    color : white;
    text-align: right;
    font-size : .6em;
    font-weight : 500;
}
.budget-rightText {
    position : absolute;
    top : -2px;
    right : 0;
    /*width: 0;*/
    /*height: 0;*/
    /*border-style: solid;*/
    /*border-width: 0 40px 10px 0;*/
    /*border-color: transparent #588f62 transparent transparent;*/
    color : white;
    /*text-align: right;*/
    font-size : .55em;
    font-weight : 500;
}
.pageschool-classbutton, .pageschool-headerbutton{
    position : relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width : 60px;
    text-align: center;
    font-weight: 500;
    height: 100%;
}
.pageschool-headerbutton {
    height : 30px;
    font-size: 1.2em;
}
.pageschool-classbutton {
    font-size: .8em;
}

.pageschool-classbutton-leftcorner{
    position : absolute;
    left : 2px;
    top : 0;
    font-size : .6em;
    font-weight: 600;
}
.pageschool-classbutton-rightcorner{
    position : absolute;
    right : 2px;
    top : 0;
    font-size : .6em;
    font-weight: 600;
}
.pageschool-classbutton-letfcorner-bottom{
    position : absolute;
    color : #565656;
    left : 2px;
    bottom : 0;
    font-size : .6em;
    font-weight: 800;
}
.pageschool-classbutton.enabled:hover, .pageschool-classbutton.disabled:hover{
    cursor: pointer;
}
.pageschool-headerbutton:hover{
    cursor: pointer;
    background-color: var(--selected-item-hover);
}
.pageschool-classbutton.enabled{
    color : var(--selected-item-hard);
    background-color: var(--msg-right-side);
}
.pageschool-classbutton.disabled {
    background-color: var(--navbar-color);
}
.pageschool-classbutton.enabled.activeCol, .activeCol {
    background-color: var(--selected-item);
    color : white;
}
.pageschool-stickycol {
    width : 120px;
    position : -webkit-sticky;
    position : sticky;
    min-width: 120px;
    max-width: 120px;
    left: 0;
    background-color: #fff;
}
.selectedClass {
    color : var(--selected-item-hard);
    font-weight : 900;
}


@media only screen and (min-width: 400px) {
    .homeWorkSectionMain {
        width : 100%;
    }
    .homeWorkSection { width : 100%; }
    .app-homeWorkSection{
        width : 50%;
        margin : 5px 10px 0 0;
    }
}
@media only screen and (max-width: 400px) {
    .homeWorkSectionMain {        width : 100%;   }
    .myTitle > h3 {font-size : 1em}
    .homeWorkSection { margin : 0 5%; width: 95% }
    .app-homeWorkSection{
        width : 95%;
        /*margin : 5px 10px 0 0;*/
    }
}
/*@media only screen and (min-width: 401px) {    .homeWorkSectionMain {        margin: 55px 0 0 0;    }  }*/

.homeWorkSectionMain {
    position: relative;
    display: flex;
    justify-content: flex-start;
    /*align-items: flex-start;*/
    height: 100%;
    border-radius: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /*left : 0;*/
}

.homeWorkSection {
    display: inline-block;
    /*border : 1px solid green;*/
    /*width : 100%;*/
    margin: 1px;
    /*border-bottom-left-radius: 10px;*/
    /*border-bottom-right-radius: 10px;*/
    border: 0.5px solid #9D9D9D;
    border-radius: 10px;
    /*z-index: 10;*/
    /*left : 0;*/
}
.hwSectionLeft{
    /*background-color: rgb(157, 157, 157);*/
    /*color:white;*/

    color: #686868;
    background-color:white;
    border-top: 0.5px solid #9D9D9D;
    border-left: 0.5px solid #9D9D9D;
    border-right: 0.5px solid #9D9D9D;
    border-bottom: 0.5px solid #9D9D9D;
    /*border : 0.5px solid #9D9D9D;*/
    /*border-radius: 10px;*/
    font-weight: 700;

    /*border : 2px solid rgb(255,230,153);*/
    width: 30%;
    padding : 5px;
    text-align: center;

    /*-moz-border-radius-topleft: 10px;*/
    /*-moz-border-radius-topright: 10px;*/
    border-top-left-radius: 10px;
    /*border-top-right-radius: 10px;*/
    border-bottom-right-radius: 10px;
}
.hwSectionRight{
    /*background-color: rgb(236, 236, 236);*/
    /*border : 1px solid #DDEBF7;*/
    border-top: 0.5px solid #9D9D9D;
    /*border-left: 0.5px solid #9D9D9D;*/
    border-right: 0.5px solid #9D9D9D;
    width: 70%;
    padding : 5px 5px 5px 15px;
    /*color : rgb(181, 34, 39);*/
    /*color : rgb(255, 230, 153);*/
    /*color : white;*/
    color : #9d9d9d;

    /*text-shadow:*/
    /*-0.5px -0.5px 0px #555a6e,*/
    /*0px -0.5px 0px #555a6e,*/
    /*0.5px -0.5px 0px #555a6e,*/
    /*-0.5px  0px 0px #555a6e,*/
    /*0.5px  0px 0px #555a6e,*/
    /*-0.5px  0.5px 0px #555a6e,*/
    /*0px  0.5px 0px #555a6e,*/
    /*0.5px  0.5px 0px #555a6e;*/

    /*text-shadow: 0 0 2px #2F75B5; !* horizontal-offset vertical-offset 'blur' colour *!*/
    /*-moz-text-shadow: 0 0 2px #2F75B5;*/
    /*-webkit-text-shadow: 0 0 2px #2F75B5;*/

    font-size : 1.1em;
    font-weight: 700;
    border-top-right-radius: 10px;
    /*color : rgb(255,230,153)*/
}
.homeWorkSectionHeader {

    background-color: #F5F5F5;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.buttonPlusDay:hover, .buttonMinusDay:hover{cursor: pointer; background-color: rgb(249, 209, 105);}
.buttonPlusDay, .buttonMinusDay
{
    display: flex;
    /*border : 4px solid rgb(235, 197, 99);*/
    border : 1.5px solid #9D9D9D;
    border-radius: 10px;
    /*color : rgb(192,143,0);*/
    color:#9D9D9D;
    justify-content: center;
    align-items: center;
    padding : 2px 20px;
    font-weight: 700;
    height: 30px;
    margin: 2px 2px;
    /*margin: ;*/
}
.homeWorkSectionHeader, .homeWorkSectionSelDate, .homeWorkCurrentSectionHeader, .homeWorkCurrentSectionHeaderL, .homeWorkCurrentSectionHeaderR, .homeWorkSectionSelSubj{
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding : 5px;*/
}
.homeWorkSectionSelDate{
    /*background-color: rgb(255,230,153);*/
    /*background-color: rgb(157, 157, 157);*/
    background-color: #F5F5F5;
    /*color : rgb(192,143,0);*/
    color : white;
    border-right : 0.5px solid #9D9D9D;
    border-left: 0.5px solid #9D9D9D;
}
.weekDay {
    font-weight: 700;
    /*color :#52aee6;*/
    color : #95c3d3;
}
.hwDate {
    /*color : rgb(192,143,0);*/
    color : #2F75B5;
    font-weight: 700;
}
.homeWorkCurrentSectionHeader{
    background-color: rgb(47,117,181);
    color: white;
}
.homeWorkCurrentSectionHeaderL{
    background-color: rgb(221, 235, 247);
    color: white;
}
.homeWorkCurrentSectionHeaderR{
    /*background-color: rgb(47,117,181);*/
    color: white;
}
.hwCurrentSectionLeft,.hwCurrentSectionRight{
    width: 50%;
    padding : 5px;
    font-weight: 700;
    text-align: center;
    /*-moz-border-radius-topleft: 10px;*/
    /*-moz-border-radius-topright: 10px;*/
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
}
.hwCurrentSectionRight {
    background-color: rgb(221, 235, 247);
    color: rgb(47, 117, 181);
    /*-moz-border-radius-topright: 10px;*/
    border-top-left-radius: 10px;
    /*border-top-right-radius: 10px;*/
}
.hwCurrentSectionLeft {
    /*background-color: rgb(47, 117, 181);*/
    /*background-color: rgb(255, 230, 153);*/
    background-color: white;
    color: rgb(47, 117, 181);
    /*color: white;*/
    /*-moz-border-radius-topleft: 10px;*/
    /*border-top-left-radius: 10px;*/
    border-top-right-radius: 10px;
}
.hwCurrentSectionLeft:hover{
    cursor:pointer;
    /*color : rgb(255,230,153)*/
    /*color : white;*/
    color: rgb(47, 117, 181);
}
.hwCurrentSectionRight:hover{
    cursor:pointer;
    /*color : rgb(192,143,0)*/
    /*color : white;*/
    color: rgb(47, 117, 181);
}
.leftSideSubjList, .rightSideSubjList{
    display: inline-block;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /*border-left : 1px solid rgb(47,117,181);*/
    /*border-right : 1px solid rgb(47,117,181);*/
    /*border-bottom : 1px solid rgb(47,117,181);*/
}
.leftSideSubjList{
    background-color: white;
    color: rgb(140, 183, 198);
    /*padding-bottom: 2px;*/
    /*background-color: rgb(47,117,181);*/
    /*color: white;*/
}
.rightSideSubjList{
    background-color: rgb(221,235,247);
    color: rgb(47,117,181);
}
.listOfHomeWork{
    border : 1px solid rgb(47,117,181);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /*background-color: #fff;*/

    /*background-color: rgb(47,117,181)*/
    background-color: white;
}
.factSubjRow {
    display: flex;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid rgb(157, 206, 223);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.subjName {
    display: inline-block;
    font-weight: 700;
    padding : 2px 5px;
    width: 30%;
    /*text-align: center;*/
}
.subjNameTitle {
    font-weight: 700;
    padding : 2px 5px;
    width: 100%;
    text-align: center;
}
.subjHomeWork{
    display: inline-block;
    margin: 0 5px;
    color : #686868;
    width: 70%;
    font-weight: 600;
    /*text-align: right;*/
}
.imgEdit > img {
    position: absolute;
    right : 2px;
    top : 2px;
    width : 15px;
    height: 15px;
    z-index: 3;
    background-color: lightblue;
    border-radius: 3px;
    opacity : 0.6;
}
.buttonPlusSubj:hover, .buttonMinusSubj:hover{cursor: pointer; background-color: rgb(221,235,247);}
.buttonPlusSubj, .buttonMinusSubj
{
    display: flex;
    border : 2px solid rgb(157, 206, 223);
    border-radius: 10px;
    color : rgb(47,117,181);
    justify-content: center;
    align-items: center;
    padding : 2px 20px;
    font-weight: 700;
    height: 30px;
    margin : 2px 2px;
}
.buttonMinusFaked
{
    display: flex;
    visibility: hidden;
    border : 2px solid rgb(157, 206, 223);
    border-radius: 10px;
    color : rgb(47,117,181);
    justify-content: center;
    align-items: center;
    padding : 2px 20px;
    font-weight: 700;
    height: 30px;
    margin : 2px 0;
}
.homeWorkSectionSelSubj{
    background-color: rgb(221,235,247);
    color : rgb(47,117,181);
}
.mainInput {
    display: inline-block;
    margin : 1px 2px 0 2px;
    height: 100px;
    width: 97%;
    font-size: 1.2em;
}
.addHomeWorkBtn {
    text-align: center;
    padding: 5px;
    border: 1px solid  rgb(47,117,181);
    background-color: rgb(255,230,153);
    border-radius: 10px;
    font-weight: 700;
    color : rgb(192,143,0);
    width: 100%;
}
.buttonsSection {
    display: flex;
    justify-content: space-between;
    padding : 2px;
    position: relative;
}
.symbButton {
    display: inline-block;
    border : 1px solid rgb(47,117,181);
    background-color: rgb(221,235,247);
    color : rgb(47,117,181);
    border-radius: 3px;
    margin : 1px;
    padding : 2px;
    text-align: center;
    width : 43px;
    font-weight: 800;
    /*height : 20px;*/
}
.btnSpace, .btnComa, .btnBackSpace  {
    display: inline-block;
    border : 1px solid rgb(47,117,181);
    background-color: rgb(221,235,247);
    color : rgb(47,117,181);
    border-radius: 3px;
    margin : 1px;
    padding : 2px;
    text-align: center;
    width : 70%;
    margin-bottom: 14px;
    font-weight: 800;
    /*height: 20px;*/
}
.btnSpace{    width : 70%; }
.btnComa{    width : 10%;}
.btnBackSpace {    width: 20%; color : #b52227; font-size: 1.2em}
.symbButton:hover{
    cursor: pointer;
}
.selectedRow {
    border : 1px solid #FFE699;
    box-shadow: 0px 0px 3px 3px #FFE699;
    border-radius: 4px;
}
.hwInfo{
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    height: 25px;
    padding : 2px;
    width : 97%;
    border-radius: 3px;
    border : 1px solid rgb(47,117,181);
    background-color: rgb(221,235,247);
    color : rgb(47,117,181);
    margin: 1%;
}
/*
.navbar {
    display:flex;
    padding : 0px 10px;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    background-color: #f0f0f0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
}
*/

.navbar-shadow {
    position: relative;
    top: 0; /* Position the navbar at the top of the page */
    width: 100%;
    height: 50px;
}
