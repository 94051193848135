.pageschool-classbutton, .pageschool-headerbutton{
    position : relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width : 60px;
    text-align: center;
    font-weight: 500;
    height: 100%;
}
.pageschool-headerbutton {
    height : 30px;
    font-size: 1.2em;
}
.pageschool-classbutton {
    font-size: .8em;
}

.pageschool-classbutton-leftcorner{
    position : absolute;
    left : 2px;
    top : 0;
    font-size : .6em;
    font-weight: 600;
}
.pageschool-classbutton-rightcorner{
    position : absolute;
    right : 2px;
    top : 0;
    font-size : .6em;
    font-weight: 600;
}
.pageschool-classbutton-letfcorner-bottom{
    position : absolute;
    color : #565656;
    left : 2px;
    bottom : 0;
    font-size : .6em;
    font-weight: 800;
}
.pageschool-classbutton.enabled:hover, .pageschool-classbutton.disabled:hover{
    cursor: pointer;
}
.pageschool-headerbutton:hover{
    cursor: pointer;
    background-color: var(--selected-item-hover);
}
.pageschool-classbutton.enabled{
    color : var(--selected-item-hard);
    background-color: var(--msg-right-side);
}
.pageschool-classbutton.disabled {
    background-color: var(--navbar-color);
}
.pageschool-classbutton.enabled.activeCol, .activeCol {
    background-color: var(--selected-item);
    color : white;
}
.pageschool-stickycol {
    width : 120px;
    position : sticky;
    min-width: 120px;
    max-width: 120px;
    left: 0;
    background-color: #fff;
}
.selectedClass {
    color : var(--selected-item-hard);
    font-weight : 900;
}
