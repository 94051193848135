.menuItem a {
    color : #1890e6;
    font-weight: 600;
    padding: 5px 3px;
    /*font-size : 0.9em;*/
}
.menuItem a:hover{
    /*color : #375c9e;*/
    text-decoration: underline;
    /*-webkit-box-shadow: 0 0 4px 7px #7DA8E6;*/
    /*-moz-box-shadow: 0 0 4px 7px #7DA8E6;*/
    /*box-shadow: 0 0 4px 7px #7DA8E6;*/
    background-color: #7DA8E6;
    color : white;
    height: 30px;
    outline: none;
    border-radius: 10px;
    /*border-radius: 2px;*/
}
.menuItemHw a {
    color : var(--menu-homework);
    font-weight: 600;
    /*font-size : 0.9em;*/
    padding: 5px 3px;
}
.menuItemHw a:hover{
    color : rgb(249, 247, 140);
    text-decoration: underline;
    /*-webkit-box-shadow: 0 0 4px 7px #7DA8E6;*/
    /*-moz-box-shadow: 0 0 4px 7px #7DA8E6;*/
    /*box-shadow: 0 0 4px 7px #7DA8E6;*/
    background-color: #7DA8E6;
    /*color : white;*/
    /*border-radius: 2px;*/
    /*padding: 5px 3px;*/
    height: 30px;
    outline: none;
    border-radius: 10px;
}
.menuBlock{
    margin : 5px;
}
.menuItems {
    display:flex;
    left : 0;
    margin-left: 10px;
    width : auto;
    min-width: 360px;
    max-width: 600px;
    font-size: .88em;
}
.menuBlock div {
    margin-right : 5px;
}
.activeItem a {
    -webkit-box-shadow: 0 0 4px 7px #7DA8E6;
    -moz-box-shadow: 0 0 4px 7px #7DA8E6;
    box-shadow: 0 0 4px 7px #7DA8E6;
    background-color: #7DA8E6;
    color : white;
    border-radius: 2px;
}
.mym-menuitem-workflow a {
    font-weight: 700;
    color : #B40530;
    font-size : 0.9em;
}
.mym-menuitem-workflow a:hover{
    text-decoration: underline;
    -webkit-box-shadow: 0 0 4px 7px #7DA8E6;
    -moz-box-shadow: 0 0 4px 7px #7DA8E6;
    box-shadow: 0 0 4px 7px #7DA8E6;
    background-color: #7DA8E6;
    color : white;
    border-radius: 2px;
}