.markBlanks {
    display: flex;
    justify-content: space-between;
    height : 280px;
}
.withborder{
    border : 1px solid lightgrey;
    border-radius: 3px;
    z-index: 2;
}
.dropdown-main-0 {
    position: absolute;
}
@media only screen and (max-width: 400px) {
    .markBlanks {
        margin : 5px 2%;
    }
    .ul12 {
        width : 50px;
    }
    .withborder{
        padding : 10px 2px;
    }
    .dropdown-main-0{
        /*margin : 5px 0 0 5px;*/
    }
    .ul12,.ul5,.ulAF {
        width : 94px;
    }
}
@media only screen and (min-width: 400px) {
    .markBlanks {
        margin : 15px 30%;
    }
    .withborder{
        padding: 10px 20px;
    }
    .ul12,.ul5,.ulAF {
        width : 104px;
    }
    .dropdown-main-0{
        margin: -5px 0 0 15px;
    }
}
ul {

    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px var(--shadow);
}

.uTT {
    width : 154px;
}
li {
    display: inline-block;
    float: left;
}
li.li-title {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 18px;
    text-decoration: none;
}
/* Change the link color to #111 (black) on hover */
li a:hover {
    background-color: var(--selected-item);
}
.nohover{
    pointer-events: none;  /**<-----------*/
}
.active {
    background-color: var(--selected-item);
}
/*.active_selected {*/
    /*background-color: #409be6; */
    /*color : lightyellow;*/
/*}*/
/*.noactive {*/
    /*background-color: #020A84; !*rgba(3,17,230,.5)*!!*#4CAF50*!;*/
    /*!*color : white;*!*/
/*}*/
@keyframes flash {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.dropdown-content3, .dropdown-content3-line {
    display: flex;
    justify-content: space-around;
    font-size: 0.7em;
    font-weight: 600;
    width: 100%;
}
.dropdown-content3-line {
    color: black;
}
.dropdown-content3 {
    color: white;
}
.dropdown-content3 ul, .dropdown-content3-line ul {
    width: 100%;
}
.dropdown-content3 ul li, .dropdown-content3-line ul li{
    width: 33.33%;
}
.dropdown-content3 ul.fivemarks li{
    width: 99.99%;
}
.dropdown-content3 ul.fiveletters li{
    width: 99.99%;
}
.dropdown-content0 {
    display: flex;
    justify-content: space-around;
    color: white;
    font-size: 0.7em;
    font-weight: 600;
    width: 100%;
}
.dropdown-content0 ul {
    width: 100%;
}
.dropdown-content0 ul li{
    width: 100%;
}
.marks12,.marks9,.marks6,.marks3,.marks0,.timetable0,.timetable1,.timetable2,.timetable3{
    /*color : var(--mark-text)*/
}
.marks12{
    background-color: var(--excel-mark);
}
.marks9{
    background-color: var(--good-mark);
}
.marks6{
    background-color: var(--norm-mark);
}
.marks3{
    background-color: var(--bad-mark);
}
.marks0{
    background-color: var(--erase-mark);
}
.marksHeader {
    background-color: var(--selected-item);
    color : #fff;
}
.timetable0{
    background-color: var(--timetable0);
    color : #2f75b5;
}
.timetable1{
    background-color: var(--timetable1);
    color : #2f75b5;
}
.timetable2{
    background-color: var(--timetable2);
    color : #2f75b5;
}
.timetable3{
    background-color: var(--timetable3);
    color : #2f75b5;
}
#markblank{
    /*display: none;*/
}
#markblank_five, #markblank_twelve, #markblank_letters {
    z-index: 3;
}

#markblank_five.selected, #markblank_twelve.selected, #markblank_letters.selected {
    border : 1px solid var(--selected-item-hard);
    border-radius: 3px;
    background-color: var(--selected-item-background);
}
#markblank_five:hover, #markblank_twelve:hover, #markblank_letters:hover{
    /*background-color: rgba(64, 155, 230, 0.16);*/
    background-color: var(--selected-item-hover);
    cursor: pointer;
}

ul.marks12>li>a,ul.marks9>li>a,ul.marks6>li>a,ul.marks3>li>a,ul.marks0>li>a,ul.timetable0>li>a,ul.timetable1>li>a,ul.timetable2>li>a,ul.timetable3>li>a {
    display: block;
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 600;
}
ul.marks12>li>a,ul.marks9>li>a {
    color : var(--excel-mark-font);
}
ul.marks6>li>a {
    color : var(--norm-mark-font);
}
ul.marks3>li>a {
    color : var(--msg-hw-color);
}
ul.marks0>li>a {
    color : #4d4d4d;
}
ul.timetable0>li>a,ul.timetable1>li>a,ul.timetable2>li>a,ul.timetable3>li>a {
    color : var(--selected-item-hard);
}
ul.marks12>li>a:hover,ul.marks9>li>a:hover,ul.marks6>li>a:hover,ul.marks3>li>a:hover,ul.marks0>li>a:hover,ul.timetable0>li>a:hover,ul.timetable1>li>a:hover,ul.timetable2>li>a:hover,ul.timetable3>li>a:hover {
    color : white;
}
.show {
    display: block;
}
#leftrect{
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 0;
    border-color: var(--mark-text) transparent transparent transparent;
}
li>a:hover {
    cursor: pointer;
}


