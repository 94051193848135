.mym-adminpageteacher-tableblock {
    margin : 5px 0;
}
.mym-adminpageteacher-description {
    position: absolute;
    margin : 5px 2%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width : 100%;
    right : 20px;
}
.addToSchool {
    border : 1px solid var(--selected-item);
    padding : 5px;
    background-color: var(--selected-item);
    color : var(--msg-left-side);
    font-weight: 600;
    border-radius: 5px;
}
.addToSchoolDisabled {
    border : 1px solid var(--selected-item);
    padding : 5px;
    background-color: var(--navbar-color);
    color : var(--border-color);
    font-weight: 600;
    border-radius: 5px;
}
.addToSchool:hover{
    color : white;
    cursor : pointer;
}
.addUserButton{
    border : 1px solid var(--selected-item-background);
    background-color: var(--selected-item-hard);
    color : var(--msg-left-side);
    font-weight: 300;
    border-radius: 3px;
    right : 0;
    top : 0;
    bottom: 0;
    position: absolute;
    height: 22px;
    margin : 2px;
    padding : 0 2px;
    /*margin-right: 20px;*/
}
.addUserButton:hover{
    right : 0;
    background-color: var(--selected-item);
    cursor : pointer;
}
.flexTD{
}
.changeStudentButton {
    background-color : #1890e6;
    color : #fff;
    border-radius : 5px;
}
.changeStudentButton:hover {
    background-color : #33ccff;
    cursor : pointer;
}