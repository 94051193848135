.emailList {
    display: inline-block;
    border : 1px solid lightblue;
    width : 70%;
    margin: 5px;
    font-size: 1em;
}
@media only screen and (max-width: 400px) {
    .emailList {
        width : 95%;
    }
}
.emailListHeader, .defaultItemInEmailList, .itemInEmailList {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding : 5px;
}
.emailListHeader {         font-weight: 700; background-color: rgb(191,191,191);}
.defaultItemInEmailList {      font-weight: 600;   background-color: rgb(242,242,242);}
.itemInEmailList {         font-weight: 400; background-color: white;}

.btnDelMail{
    border : 1px solid darkgrey;
    padding : 5px;
    font-weight: 700;
    text-align: center;
}
.fastRegAndMail {
    border : .5px solid darkgrey;
    border-radius: 5px;
    margin : 0 0 0 5px;
    padding: 0 10px 2px 10px;
    text-align: center;
    background-color: #FFEB9C;
}
.fastRegAndMail:hover{
    cursor : pointer;
    color: #6a6a6a;
}
.emaiListAddButton{
    border : 0.5px solid #4472C4;
    border-radius: 5px;
    padding : 0 20px;
    font-weight: 600;
    font-size: 1.5em;
    color : #588f62;
}
.emaiListAddButton:hover{
    cursor: pointer;
    background-color: lightblue;
}
.emaiListRemoveButton{
    border : 0.5px solid #4472C4;
    border-radius: 5px;
    padding : 0 20px;
    font-weight: 600;
    font-size: 1.1em;
    color: #b40530;
}
.emaiListRemoveButton:hover{
    cursor: pointer;
    background-color: lightblue;
}
.email-buttons{
    display: flex;
    justify-content: space-between;
    width : 50%;
}