@media only screen and (max-width: 400px) {
    h2{
        font-size : 0.9em;
    }
}
.primary-school {
    background-color: rgb(255,251,235);
    color: rgb(156, 101, 0);
}
.secondary-school {
    background-color: rgb(255, 244, 205);
    color: rgb(156, 101, 0);
}

.high-school {
    /*background-color: rgb(225,237,247);*/
    /*color: rgb(44,110,170);*/
    background-color: rgb(255,235,156);
    color: rgb(156, 101, 0);
}
.school {
    /*display : flex;*/
    /*flex-direction: row;*/
    margin: 5px 10%;
    text-align: left;
    padding: 0 10px 2px 10px;
    border-radius: 5px;
}
.btn-class {
    margin: 0 3px 3px 0;
    padding: 5px 22px;
    border-radius: 5px;
    border : 1px solid darkgrey;
}
.btn-class:hover {
    background-color: #ececec;
    cursor : pointer;
    color : black;
}
.btn-active {
    /*border : solid 2px black;*/
    background-color: #b4b4b4;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    color: white;
    font-weight: 500;
    border-radius: 5px;
}
h2 {
    margin: 0;
    /*font-family:Helvetica Neue,Helvetica,Arial,sans-serif;*/
}
.buttonsList {
    display : flex;
    flex-direction: row;
}
.classCount {
    position : absolute;
    font-size: 0.7em;
    right : 7px;
    top : 0;
    color : #4472C4;
    font-weight: 700;
}
