.insAndOutsBlock {
    display: flex;
    width : 100%;
    height : 20%;
    margin : 10px 0;
}
.insAndOutsFactBlock {
    display: flex;
    width : 100%;
    margin : 10px 0;
}
.insBlock{
    border : 1px solid #2f75b5;
    display: inline-block;
    width : 80%;
}
.outsBlock{
    border : 1px solid #588f62;
    display: inline-block;
    width : 80%;
}
.insFactBlock{
    border : 1px solid #2f75b5;
    /*display: inline-block;*/
    width : 100%;
}
.outsFactBlock{
    border : 1px solid #588f62;
    display: inline-block;
    width : 10%;
}
.budget-rightArrow {
    position : absolute;
    top : 0;
    right : 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 44px 12px 0;
    border-color: transparent #588f62 transparent transparent;
    color : white;
    text-align: right;
    font-size : .6em;
    font-weight : 500;
}
.budget-rightText {
    position : absolute;
    top : -2px;
    right : 0;
    /*width: 0;*/
    /*height: 0;*/
    /*border-style: solid;*/
    /*border-width: 0 40px 10px 0;*/
    /*border-color: transparent #588f62 transparent transparent;*/
    color : white;
    /*text-align: right;*/
    font-size : .55em;
    font-weight : 500;
}