.subjectsByDayOfWeek {
    /*border : 1px solid #000;*/
    display: flex;
    flex-wrap: wrap;
    /*padding-right : 10px;*/
    /*padding-bottom: 10px;*/
    width : 60%;
}
.timetableMain {
    display : flex;
    /*padding-right : 15px;*/
}
.subjListTableBlock{
    margin-right: 10px;
    margin-bottom: 10px;
}
.timeTableSelected{
    background-color: var(--selected-item-background);
}
.lockTimetable-btn {
    border: 2px solid #b52227;
    border-radius: 5px;
    font-size: .8em;
    padding: 5px;
    text-align: center;
    background-color: #bdd7ee;
    width: 40%;
    margin-left: 5%;
}
.lockTimetable-btn:hover{
    cursor: pointer;
    background-color: #70baf7;
    color : white;
}
.timetable__subj_name-col{
    font-size : .8em;
    font-weight : bold;
    color : #2f75b5;
}