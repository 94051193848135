table {
    width : 100%;
    table-layout: fixed;
}
.left-text {
    text-align: left;
}
.center-text> input[type="checkbox"]{
    margin: 0 40%;
}
th {
    /*height: 30px;*/
}
td {
    position: relative;
    font-size : 1em;
}
.inputEditor {
    position : absolute;
    height: 90%;
    width : 99%;
    border : 1px solid #52aee6;
    left : 0;
    top : 0;
}
tr#row-1, tr#row-2{
    background-color: lightgrey;
}
.verification{
    background-color: rgb(255,235,156);
}
.verified{
    background-color: rgb(198,239,206)
}
.mym-universaltable-container {
    position : relative;
    overflow : hidden;
    /*overflow : overflox-x;*/
    /*height: 500px;*/
}
.mym-btn-add-lang-alias, .mym-show-saldo {
    position : absolute;
    top : -3px;
    border: 2px solid var(--selected-item-hard);
    text-align: center;
    border-radius: 5px;
    width : auto;
    padding : 0 10px;
    height : 20px;
    margin : 3px;
    font-size: .9em;
    z-index : 2;
    color : white;
}
.mym-btn-add-lang-alias{
    left : 3%;
    background-color: var(--selected-item);
}
.mym-show-saldo{
    left : 120px;
    background-color: var(--menu-item);
}
.mym-btn-add-lang-alias:hover {
    background-color: var(--erase-mark);
    color : var(--selected-item-hard);
    cursor: pointer;
}
.rotate {
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /*width: 1.5em;*/
}
.rotate div {
    -moz-transform: rotate(-90.0deg);  /* FF3.5+ */
    -o-transform: rotate(-90.0deg);  /* Opera 10.5 */
    -webkit-transform: rotate(-90.0deg);  /* Saf3.1+, Chrome */
    filter:  progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083);  /* IE6,IE7 */
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)"; /* IE8 */
    margin-left: -1em;
    margin-right: -1em;
    padding : 25px 0;
}
.plusSaldo {
    position : absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right : 1px;
    top : -3px;
    border-radius : 9px;
    height : 15px;
    width : 15px;
    /*text-align: center;*/
    /*font-weight: 800;*/
    font-size: 1em;
    color : white;
    border : 0;
    line-height: 200px;
    /*white-space: nowrap;*/
    /*vertical-align: middle;*/
    /*padding-top: -5px;*/
    background-color: #55b162;
}