.bm-burger-button {
    position:absolute;
}

#page-wrap {
    text-align: center;

    /* Prevent sidebar from showing a scrollbar on page */
    overflow: auto;
}

/* Individual item */
.bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
    color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 50px;
    top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #555a6e;
    /*right : 10px;*/
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
    background: #555a6e;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    left :0;
    /*top : 0;*/
}

.bm-menu-my {
    left : 0;
    top : 0;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
    top : 0;
    background: rgba(0, 0, 0, 0.18);
}
.mym-menu-title {
    border: none;
    font-weight : 700;
    outline: none;
}

